.footer {
  @include flexbox();
  @include justify-content(space-between);
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(63, 63, 68, 0.15), 0 0 0 2px rgba(63, 63, 68, 0.05);
  padding: 20px 40px;
  font-size: 12px;
  font-weight: 400;
  @include flex-shrink(0);

  @media screen and (max-width: 1000px) {
    display: block;
  }

  @media screen and (max-width: 769px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media screen and (max-width: 550px) {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 10px;
  }

  &__item {
    @include flexbox();

    @media screen and (max-width: 1000px) {
      @include flex-direction(column);
    }

    &--lg {
      max-width: 62%;
      @media screen and (max-width: 1000px) {
        max-width: 100%;
      }
    }

    & + & {
      padding-left: 30px;

      @media screen and (max-width: 1000px) {
        margin-top: 15px;
        padding-left: 0;
      }
    }

    &:first-child .footer__wrap-content a{
      display: inline-block;
      padding-right: 16px;
      position: relative;

      &:after{
        content: "";
        position: absolute;
        height: 10px;
        width: 10px;
        top: 2px;
        right: 0;
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 283.922 283.922' style='enable-background:new 0 0 283.922 283.922; fill:%230067ff' xml:space='preserve'%3E%3Cg%3E%3Cpath d='M266.422,0h-97.625c-9.65,0-17.5,7.851-17.5,17.5c0,9.649,7.85,17.5,17.5,17.5h55.377l-92.375,92.374 c-3.307,3.305-5.127,7.699-5.127,12.375c0,4.676,1.819,9.069,5.125,12.371c3.306,3.309,7.699,5.13,12.375,5.13 c4.674,0,9.069-1.82,12.376-5.127l92.374-92.375v55.377c0,9.649,7.851,17.5,17.5,17.5c9.649,0,17.5-7.851,17.5-17.5V17.5 C283.922,7.851,276.071,0,266.422,0z'/%3E%3Cpath d='M201.137,253.922H30V82.785h128.711l30-30H15c-8.284,0-15,6.716-15,15v201.137c0,8.284,6.716,15,15,15h201.137 c8.284,0,15-6.716,15-15V95.211l-30,30V253.922z'/%3E%3C/g%3E%3C/svg%3E");
      }
    }
  }

  &__logo {
    @include flex-shrink(0);
    margin-right: 10px;

    img {
      width: 101px;
      height: 40px;
    }

    @media screen and (max-width: 1000px) {
      margin-bottom: 15px;
      margin-right: 0;
    }
  }


  &__wrap-content {
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(space-between);
    width: 100%;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;

    @media screen and (max-width: 1000px) {
      @include justify-content(flex-start);
    }

    @media screen and (max-width: 550px) {
      font-size: 10px;
    }
  }

  &__email {
    font-weight: 700;
    line-height: 16px;
    margin-top: 10px;

    a {
      margin-left: 5px;
      font-weight: 600;
      white-space: nowrap;
    }
  }

  p {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    @media screen and (max-width: 550px) {
      font-size: 10px;
    }
  }
}

.nav-black {
  @include flexbox();

  &__item + &__item {
    margin-left: 25px;

    @media screen and (max-width: 1100px) {
      margin-left: 15px;
    }
  }

  &__link {
    font-size: 12px;
    font-weight: 600;
    line-height: 22px;
    color:$primary-color;
    letter-spacing: 0.01em;
    white-space: nowrap;

    @media screen and (max-width: 550px) {
      font-size: 10px;
    }
  }
}

.footer{
  &__logo-wrap{
    flex-shrink: 0;

    @media screen and (max-width: 1000px){
      margin-bottom: 15px;
    }
  }

  &__content{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-grow: 1;
    width: 100%;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
  }

  &__title-wrap{
    margin-right: 10px;

    @media screen and (max-width: 1000px){
      margin-right: 0;
      width: 100%;
    }
  }
  &__nav-wrap{
    padding-right: 35px;

    .nav-black__item + .nav-black__item{
      @media screen and (max-width: 1100px){
        margin-left: 25px;
      }
    }

    @media screen and (max-width: 1000px){
      padding-right: 0;
      width: 100%;
      order: 1;
      margin-top: 15px;
    }
  }

  &__text-wrap{
    margin-bottom: 5px;
  }

  &__link-wrap{
    margin-right: 10px;

    a{
      display: inline-block;
      padding-right: 16px;
      position: relative;

      &:after{
        content: "";
        position: absolute;
        height: 10px;
        width: 10px;
        top: 2px;
        right: 0;
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 283.922 283.922' style='enable-background:new 0 0 283.922 283.922; fill:%230067ff' xml:space='preserve'%3E%3Cg%3E%3Cpath d='M266.422,0h-97.625c-9.65,0-17.5,7.851-17.5,17.5c0,9.649,7.85,17.5,17.5,17.5h55.377l-92.375,92.374 c-3.307,3.305-5.127,7.699-5.127,12.375c0,4.676,1.819,9.069,5.125,12.371c3.306,3.309,7.699,5.13,12.375,5.13 c4.674,0,9.069-1.82,12.376-5.127l92.374-92.375v55.377c0,9.649,7.851,17.5,17.5,17.5c9.649,0,17.5-7.851,17.5-17.5V17.5 C283.922,7.851,276.071,0,266.422,0z'/%3E%3Cpath d='M201.137,253.922H30V82.785h128.711l30-30H15c-8.284,0-15,6.716-15,15v201.137c0,8.284,6.716,15,15,15h201.137 c8.284,0,15-6.716,15-15V95.211l-30,30V253.922z'/%3E%3C/g%3E%3C/svg%3E");
      }

    }
    @media screen and (max-width: 1000px){
      width: 100%;
    }
  }

  &__email-wrap{
    .footer__email{
      margin-top: 0;
    }

    @media screen and (max-width: 1000px){
      margin-top: 10px;
      width: 100%;
      order: 1;
    }
  }
}
