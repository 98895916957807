@import 'variables';
@import 'mixins';

html {
  @include box-sizing(border-box);
}

html,body{
  height: 100%;
}

.fixed{
  overflow-y: hidden;
  position: fixed;
  left: 0;
  width: 100%;
  overflow-y: scroll;
}

*,
*:before,
*:after {
  @include box-sizing(inherit);
}

.svg-sprite {
  display: none;
}

body {
  max-width: 100%;
  min-height: 100vh;
  font-size: 14px;
  line-height: 21px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  color: $color-text;
  background: $bg-section;
  overflow: auto;
  overflow-x: hidden;

  & > div {
    height: 100%;

    & > div {
      @include flexbox();
      @include flex-direction(column);
      height: 100%;
    }
  }
}

h1,h2,h3,h4,h5 {
  &:first-child{
    margin-top: 0;
  }

  &+&{
    margin-top: 0;
  }
}

h1.h1{
  font-size: 36px;
  font-weight: 800;
  margin-bottom: 0.9em;
  line-height: 1.2em;
  font-family: 'Futura bold';
  letter-spacing: -.9px;

  @media screen and (max-width: 1300px) {
    font-size: 32px;
  }

   @media screen and (max-width: 766px) {
    font-size: 25px;
  }
}

h4{
  line-height: 1.4em;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.017em;
  font-family: 'Futura med';

  @media screen and (max-width: 1300px) {
    font-size: 18px;
  }
}

h5{
  font-size: 15px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 12px;
  @media screen and (max-width:550px)  {
    font-size: 13px;
    margin-bottom: 10px;
  }
}

input {
  outline: 0;
  box-shadow: none;
  border-radius: 0;

  &[type="text"] {
    -webkit-appearance: none;
  }
}

a {
  text-decoration: none;
  transition: all 0.3s;
  color: $color-link;

  &:hover{
    text-decoration: none!important;
  }
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}


.btn-landing{
  background: $primary-color;
  width: 188px;
  display: inline-block;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  line-height: 20px;
  padding: 15px 5px;
  border-radius: 4px;
  outline: none;
  border: 0;
  cursor: pointer;

  &--main{
    @media screen and (max-width: 1300px) {
      padding: 10px 5px;
    }
  }
}

h6, .h6 {
  font-size: 15px;
  letter-spacing: 0.1px;
  margin-top: 0;
  margin-bottom: 5px;
}

