.document-list {
  margin-top: 40px;
}

.document {
  @include flexbox();
  @include align-items(flex-start);
  text-decoration: underline;
  color: $color-text;

  & + & {
    margin-top: 10px;
  }

  &__icon {
    margin-right: 10px;
    display: block;
    flex-shrink: 0;
  }

  &:hover {
    opacity: 0.5;
    text-decoration: underline;
  }
}



