
.filter{
  width: 100%;
  @include flexbox();
  @include justify-content(space-between);
  padding: 0 0 10px;
  background: transparent;
  position: relative;

  &--center {
    @include justify-content(center);
    @include flex-wrap(wrap);
  }

  @media screen and (max-width:1410px ) {
      @include flex-wrap(wrap);
      @include justify-content(center);
  }

  @media screen and (max-width: 1000px){
      padding-top: 10px;
  }

  &__item{
      padding-top: 10px;
      @include flexbox();
      @include flex-wrap(wrap);
      @include align-items(flex-end);

      @media screen and (max-width:550px ) {
          width: 100%;
          display: block;
      }
  }

  &__item +&__item{
      margin-left: 30px;

      @media screen and (max-width:550px ) {
        width: 100%;
        margin-left: 0;
      }
  }

   &__options + &__item {
      margin-left: 30px;

      @media screen and (max-width:550px ) {
          margin-left: 0;
      }
  }
  &__options{
      @include flexbox();
      @include flex-wrap(wrap);
      @include align-items(flex-end);
      @include flex-grow(1);
      @include justify-content(flex-end);
      margin-left: 30px;

      @media screen and (max-width:1380px ) {
          @include flex-grow(0);
      }

      @media screen and (max-width:550px ) {
          width: 100%;
          margin-left: 0;
      }

      &-item{
          padding-top: 10px;
          min-height: 40px;

          @media screen and (max-width:550px) {
            width: 100%;
            display: block;
          }

          & + & {
            margin-left: 30px;
            @media screen and (max-width:1380px ) {
                margin-left: 20px;
            }

            @media screen and (max-width:550px) {
                margin-left: 0px;
            }
          }
      }


  }
  &__link{
      color: $primary-color;

      @media screen and (max-width:665px ) {
          font-size: 13px;
      }

      @media screen and (max-width:550px) {
          font-size: 14px;
      }
  }

  &__button{
      box-shadow: 0 1px 3px rgba(63,63,68,.15), 0 0 0 1px rgba(63,63,68,.05);
      border-radius: 4px;
      background-color: #fff;
      padding: 10px 22px 10px 10px;
      color: $primary-color;
      cursor: pointer;
      display: block;
      max-width: 300px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media screen and (max-width:665px ) {
          font-size: 13px;
      }
  }
}
