@import 'mixins';
@import 'variables';

.header{
  position: sticky;
  top: 0;

  @include flexbox();
  @include align-items(center);
  @include flex-wrap(wrap);
  padding-left: 40px;
  padding-right: 40px;
  background-image: $gradient-color;
  z-index: 10;

  @media screen and (max-width:1024px){
    padding: 22px 40px;
    @include justify-content(space-between);
  }

  @media screen and (max-width:769px){
    padding-left: 20px;
    padding-right: 20px;
  }

  @media screen and (max-width:550px){
    padding: 10px;
  }

  &__nav{
    @include flexbox();
    @include flex-grow(1);
    @include justify-content(space-between);

    @media screen and (max-width:1024px){
      @include flex-grow(initial);
    }
  }

  &__logo{
    margin-right: 70px;
  }
}

.logo{
  @include flexbox();
  cursor: pointer;

  img{
    width: 50px;
    height: 50px;
  }
}

.navigation{

  @media screen and (max-width:1024px) {
    position: fixed;
    background:#fff;
    right: 0;
    top: 0;
    width: 250px;
    z-index: 50;
    height: 100%;
    @include flex-direction(column);
    @include flexbox();
    -webkit-transform: translateX(250px);
    transform: translateX(250px);
    transition: all .5s;
    box-shadow: 0 0 20px rgba(0,0,0,.2);
  }

  &.active{
    @media screen and (max-width:1024px) {
      transform: translateX(0);
    }
  }

  &__top-mobile{
    display: none;
    padding: 15px 20px;
    @media screen and (max-width:1024px){
      @include flexbox();
      width: 100%;
      @include justify-content(flex-end);
      @include flex-shrink(0);
    }
  }
}

.navigation-list{
  @include flexbox();
  height: 100%;
  margin: 0;
  padding: 0;

  @media screen and (max-width:1024px) {
    @include flex-direction(column);
    @include flex-grow(1);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }


  &__item +&__item{
    margin-left: 47px;
    @media screen and (max-width:1024px){
      margin-left: 0;
    }
  }

  &__item{

     &.active{

      .navigation-list__link:after{
        width: 100%;

        @media screen and (max-width:1024px){
          height: 100%;
          width: 4px;
        }
      }
    }
  }

  &__link{
    color: #fff;
    font-size: 15px;
    letter-spacing: 0.05em;
    font-weight: 600;
    @include flexbox();
    @include align-items(center);
    height: 100%;
    padding: 30px 0px 30px 0px;
    position: relative;
    cursor: pointer;
    letter-spacing: 0.01em;

    @media screen and (max-width:1024px){
      color: $primary-color;
      padding: 15px 20px;
    }

    &:after{
      content: '';
      display: block;
      width: 0;
      height: 4px;
      background: $accent-color;
      position: absolute;
      left: 50%;
      @include transform(translateX(-50%));
      bottom: 0;
      transition: all .2s;

      @media screen and (max-width:1024px){
        width: 4px;
        height: 0;
        left: 0;
        top: 50%;
        transform: none;
        @include transform(translateY(-50%));
        pointer-events: none;
      }
    }

    &:hover:after{
      width: 100%;

      @media screen and (max-width:1024px){
        height: 0;
        width: 4px;
        pointer-events: none;
      }
    }

    img{
      margin-right: 21px;
      @include  flex-shrink(0);

      @media screen and (max-width:1024px){
        display: none;
      }
    }
  }
}

.nav-toggle{
  position: relative;
  z-index: 2;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  -webkit-appearance: none;
  display: none;

  @media screen and (max-width:1024px) {
    display: block;
  }

  &.active{

    .line{
      background: #9f9f9f;
      width: 20px;
      height: 3px;
    }

    .line:first-child{
      @include transform(translateY(11px) rotate(45deg));
    }

    .line:nth-child(2){
      opacity: 0;
    }

    .line:last-child{
      @include transform(translateY(-11px) rotate(-45deg));
    }
  }
}

.line{
  display: block;
  width: 40px;
  height: 3px;
  border-radius: 1px;
  background-color: #fff;
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s,-webkit-transform .3s;
}

.line+.line{
  margin-top: 8px;
}
