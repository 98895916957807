@import 'variables';
@import 'mixins';

.hide {
  display: none;
}

.select {
  @include flexbox() ;
  @include flex-direction(column);

  &__lable {
    font-size: 13px;
    font-weight: 600;
    line-height: 22px;

    @media screen and (max-width: 550px) {
      line-height: 1.2em;
    }
  }

  &__wrap {
    margin-top: 10px;
    @include flexbox() ;
    @include align-items(center) ;

    &--md {
      width: 140px;

      @media screen and (max-width: 550px) {
        width: 100%;
      }
    }
  }

  &__text {
    font-size: 14px;
    font-weight: 600;
    padding-left: 9px;
    padding-right: 9px;
  }

  & + & {
    margin-left: 100px;

    @media screen and (max-width: 1380px) {
      margin-left: 20px;
    }

    @media screen and (max-width: 550px) {
      margin-left: 0;
      padding-top: 10px;
    }
  }
}


.input-choice {
  position: relative;
  box-shadow: 0 1px 3px rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05);
  border-radius: 4px;
  background-color: #ffffff;
  outline: none;

  &__link {
    display: flex;
    position: relative;
    padding: 10px 25px 10px 10px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.4em;
    word-break: break-all;
    cursor: pointer;
    color: $primary-color;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .icon {
      width: 8px;
      height: 5px;
      fill: $primary-color;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
      z-index: auto;
      pointer-events: none;

      &.external-link {
        top: 48%;
        right: 6px;
        width: 13px;
        height: 13px;
      }
    }

    &:before {
      position: absolute;
      top: 25%;
      right: 0;
      content: "";
      height: 50%;
      width: 25px;
      background: #fff;
    }

    &:before {
      border-radius: 4px;
    }
  }

  &__content {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    box-shadow: 0 1px 3px rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05);
    background: #fff;
    max-height: 200px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 6;
  }

  &__item {
    font-size: 12px;
    font-weight: 600;
    padding: 10px;
    line-height: 1.4em;
    cursor: pointer;
    transition: all .2s;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;

    &:hover {
      color: $primary-color;
    }

    &.active {
      color: $primary-color;
    }
  }

  &__item + &__item {
    border-top: 1px solid #f1f1f1;
  }

  &.active {
    .input-choice__content {
      display: block;
    }
  }


  &--green {
    .input-choice__link {
      color: #fff;
      background: $accent-color;

      .icon {
        fill: #fff;
      }

      &:before {
        background: $accent-color;
      }
    }
  }

  &--sources {
    .input-choice__item {
      padding-right: 25px;
      position: relative;

      .icon {
        width: 10px;
        height: 10px;
        fill: #0067ff;
        position: absolute;
        top: 48%;
        transform: translateY(-50%);
        right: 8px;
      }
    }
  }

  &--lg {
    width: 155px;
    @media screen and (max-width: 550px) {
      width: 100%;
    }
  }

  &--md {
    width: 140px;
    @media screen and (max-width: 550px) {
      width: 100%;
    }
  }

  &--sm {
    width: 70px;
    @media screen and (max-width: 550px) {
      width: 100%;
    }
  }

  &--xs {
    width: 70px;

    @media screen and (max-width: 550px) {
      width: 100%;
    }
  }
}

.panel {
  border-radius: 4px;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  margin-top: 20px;

  &__header {
    position: sticky;
    z-index: 5;
    top: 81px;

    @media screen and (max-width: 1024px) {
      top: 94px;
    }

    @media screen and (max-width: 550px) {
      top: 70px;
    }

    background: white;
    padding: 23.5px 30px;
    padding-right: 10px;
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
    box-shadow: 0 1px 3px rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05);

    @media screen and (max-width: 1200px) {
      padding: 15px 10px;
    }

    @media screen and (max-width: 1000px) {
      @include flex-wrap(wrap);
    }
  }

  &__ttl {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.71px;
    line-height: 1.4em;
    white-space: nowrap;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and (max-width: 1000px) {
      white-space: normal;
    }

    @media screen and (max-width: 768px) {
      font-size: 14px;
      letter-spacing: 0.4px;
      height: 40px;
    }
  }

  &__options {
    @include flexbox();
    @include align-items(center);
    @include flex-shrink(0);

    @media screen and (max-width: 1000px) {
      width: 100%;
      @include justify-content (space-between);
      @include flex-wrap(wrap);
    }

    .tooltip{
      @media screen and (max-width: 1000px) {
        margin-top: 15px;
        margin-right: 10px;
      }
    }
  }

  &__link {
    color: $primary-color;

    @media screen and (max-width: 1000px) {
      margin-right: 20px;
      margin-top: 15px;
    }

    &:hover {
      text-decoration: none;
    }

    & + * {
      margin-left: 62px;

      @media screen and (max-width: 1000px) {
        margin-left: 0;
        margin-top: 15px;
      }
    }

    * ~ & {
      margin-left: 30px;
      @media screen and (max-width: 1000px) {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  &__button {
    box-shadow: 0 1px 3px rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05);
    border-radius: 4px;
    background-color: #ffffff;
    padding: 10px 22px 10px 10px;
    color: $primary-color;
    cursor: pointer;

    @media screen and (max-width: 550px) {
      width: 100%;
      padding: 10px;
      font-size: 12px;
    }
  }

  &__content {
    padding: 10px;
    -webkit-overflow-scrolling: touch;

    @media screen and (max-width: 1200px) {
      padding: 15px 0;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

table {
  width: 100%;
  border: 1px solid #e9ebf2;
  font-size: 13px;

  @media screen and (max-width: 1000px) {
    font-size: 12px;
    min-width: 950px;
  }

  .border-right {
    border-right: 1px solid #d4d6dd;
  }

  td {
    border: 1px solid #e9ebf2;
    padding: 5px 10px;
    height: 53px;
    word-break: break-word;

    @media screen and (max-width: 1000px) {
      padding: 8px 5px;
      height: auto;
    }
  }

  th {
    font-size: 14px;
    font-weight: 700;
    height: 76px;
    padding: 5px 10px;
    border: 1px solid #e9ebf2;
    border-bottom: 1px solid #d4d6dd;
    text-align: left;

    @media screen and (max-width: 1200px) {
      width: auto;
    }

    @media screen and (max-width: 1000px) {
      padding: 8px 5px;
      font-size: 13px;
      height: auto;
    }
  }

  .blue {
    color: $primary-color;
    text-align: right;
    min-width: 75px;
    max-width: 143px;
  }
}

.sorting {
  cursor: pointer;
  white-space: nowrap;

  &:after {
    content: "";
    width: 10px;
    background: url("../theme/icon-arrow-fill.svg");
    height: 5px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 7px;
    @include flex-shrink(0);
  }
}

.sorting.-sort-asc {
  &:after {
    transform: rotateX(180deg);
  }
}

.right {
  text-align: right;
}

.dropdown {
  position: relative;
  outline: none;

  &__link {
    position: relative;
    padding: 12px 25px 12px 10px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5em;
    word-break: break-all;
    font-size: 12px;
    letter-spacing: 0.5px;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    display: block;
  }

  .icon {
    width: 8px;
    height: 5px;
    fill: #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    transition: all .2s;
  }

  &__content {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    box-shadow: 0 1px 3px rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05);
    border-radius: 4px;
    background-color: #ffffff
  }

  &.active {
    .dropdown__content {
      display: block;
    }

    .icon-arrow-choice {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  &__item {
    @include flexbox();
    @include align-items(center);
    padding: 10px 10px;
    font-size: 10px;
    font-weight: 400;
    line-height: 1.4em;
    cursor: pointer;
    color: $color-text-dropdown;
    letter-spacing: 0.03em;
  }

  &__item + &__item {
    border-top: 1px solid #f3f3f3;
  }

  &__logo {
    width: 36px;
    height: 19px;
    border-radius: 10px;
    font-size: 10px;
    text-transform: uppercase;
    margin-right: 10px;
    font-weight: 600;
    text-align: center;
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);

    &--csv {
      background-color: rgba(0, 100, 255, 0.1);
      color: #0464FF;
    }

    &--xml {
      background-color: rgba(255, 0, 162, 0.1);
      color: #ff00a2;
    }

    &--prs {
      background-color: rgba(255, 155, 0, 0.1);
      color: #ff9b00;
    }
  }

  &--blue {
    .dropdown__link {
      background-image: linear-gradient(225deg, #009dff 0%, #0064ff 100%);
    }
  }

  &--green {
    .dropdown__link {
      background: $accent-color;
    }
  }

  &--up {
    .icon {
      transform: translateY(-50%) rotate(180deg);
    }

    .dropdown__content {
      top: auto;
      bottom: 100%;
    }

    &.active {

      .icon {
        transform: translateY(-50%) rotate(0);
      }
    }
  }

  &--lg {
    width: 184px;
  }

  &--sm {
    width: 109px;
    @media screen and (max-width: 550px) {
      width: 100%;
    }

    .dropdown__link {
      padding: 10px 25px 10px 10px;
      font-size: 14px;
      line-height: 1.4em;
    }

    .dropdown__item {
      padding-right: 5px;
    }

    .dropdown__logo {
      margin-right: 6px;
    }
  }
}

.filter-group {
  @include flexbox();
  background: #fff;
  z-index: 8;

  @media screen and (max-width: 1000px) {
    @include flex-wrap(wrap);
  }

  &__item {
    width: calc(100% / 3);
    border: 1px solid #e1e8ee;

    @media screen and (max-width: 1000px) {
      width: 100%;
      border-left: none;
      border-right: none;
    }

    & + & {
      border-left: none;

      @media screen and (max-width: 1000px) {
        border-top: none;
      }
    }
  }

}

.filter-group-dropdown {
  position: relative;
  z-index: 1;
  outline: none;
  height: 100%;

  @media screen and (max-width: 1000px) {
    z-index: auto;
  }

  &__link {
    @include flexbox();
    padding: 16px 50px;
    cursor: pointer;

    @media screen and (max-width: 1200px) {
      padding: 16px 40px;
    }

    @media screen and (max-width: 769px) {
      padding: 10px 20px;
      @include align-items(center);
    }

    @media screen and (max-width: 550px) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  &__subttl {
    font-size: 13px;
    font-weight: 600;
    line-height: 22px;
    text-transform: uppercase;
    color: #97a3b4;

    @media screen and (max-width: 769px) {
      font-size: 12px;
    }
  }

  &__text {
    width: 100%;
    height: 100%;
  }

  &.active {

    .filter-group-dropdown__content {
      display: block;
    }
  }

  &__content {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    margin-top: 1px;
    background: #fff;
    box-shadow: 0 1px 3px rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05);
    display: none;
    z-index: 1;
    max-height: 168px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;


    @media screen and (max-width: 1000px) {
      margin-top: 0;
    }
  }

  &__item {
    padding: 5px 50px;
    cursor: pointer;
    height: 0;
    min-height: 42px;
    line-height: 1.2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;


    @media screen and (max-width: 1200px) {
      padding-left: 40px;
      padding-right: 40px;
    }

    @media screen and (max-width: 769px) {
      padding-left: 20px;
      padding-right: 20px;
    }

    @media screen and (max-width: 550px) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  &__item + &__item {
    border-top: 1px solid #f1f1f1;
  }

  &__ttl {
    position: relative;
    color: #37394c;
    font-size: 20px;
    font-weight: 700;
    padding-right: 20px;
    margin-top: 10px;
    line-height: 1.05;
    word-break: break-word;
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;

    @media screen and (max-width: 769px) {
      font-size: 18px;
      margin-top: 5px;
    }

    .icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      width: 16px;
      height: 10px;
      fill: #37394c;
      pointer-events: none;

      @media screen and (max-width: 769px) {
        width: 14px;
        height: 14px;
      }
    }
  }

  &__img {
    @include flex-shrink(0);
    width: 62px;
    height: 62px;
    margin-right: 46px;
    border-radius: 50%;
    background-color: rgba(0, 100, 255, 0.06);

    @media screen and (max-width: 769px) {
      margin-right: 20px;
      width: 40px;
      height: 40px;
    }
  }
}

.radio {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-shadow: 0 1px 3px rgba(63, 63, 68, .15), 0 0 0 1px rgba(63, 63, 68, .05);
  border-radius: 4px;
  @include flex-grow(1);
  overflow: hidden;

  input {
    position: absolute;
    opacity: 0;
  }

  &__link {
    padding: 10px 25px 10px 10px;
    line-height: 1.4em;
    word-break: break-all;
    background: #fff;
    display: block;
  }

  input:checked ~ &__link {
    background-image: $gradient-color;
  }

  input:checked ~ &__link {
    color: #fff;
  }

  &--first {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &--last {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}


.tab-block {
  margin-top: 20px;
}

.tab-list {

  &__item + &__item {
    margin-top: 28px;

    @media screen and (max-width: 768px) {
      margin-top: 15px;
    }
  }
}

.tab-choice {
  &__link {
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
    @include flex-wrap(wrap);
    box-shadow: 0 1px 3px rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05);
    border-radius: 4px;
    background-color: #ffffff;
    padding: 24px 31px 24px 24px;
    min-height: 75px;
    cursor: pointer;
    z-index: 2;

    position: sticky;
    top: 84px;

    @media screen and (max-width: 550px) {
      top: 60px;
    }

    & > span {
      color: $color-text-dropdown;
      font-size: 20px;
      letter-spacing: 0.71px;
      flex-basis: 50%;
    }

    @media screen and (max-width: 768px) {
      padding: 15px 20px;
      min-height: auto;
      font-size: 18px;
    }

    @media screen and (max-width: 768px) {
      font-size: 15px;
    }

    & > span {
      @media screen and (max-width: 1000px) {
        width: calc(100% - 31px) !important;
        flex-basis: auto !important;
      }

    }

    .tab-choice__container {
      @media screen and (max-width: 1000px) {
        margin: 0 !important;
      }
    }

    & > .icon {
      width: 16px;
      height: 10px;
      fill: $color-text-dropdown;
      transform: rotate(0deg);
      transition: all .3s;
      position: absolute;
      top: 32px;
      right: 25px;

      @media screen and (max-width: 768px) {
        width: 14px;
        height: 14px;
        top: 18px;
      }
    }
  }

  &__container {
    @include flexbox();
    @include align-items(center);
    @include justify-content (space-between);
    @include flex-wrap(wrap);
    margin-left: auto;
    margin-right: 24px;

    @media screen and (max-width: 1000px) {
      margin: 24px 0;
      width: 100%;
    }

    .tooltip{
      @media screen and (max-width: 1000px) {
        margin-top: 15px;
        margin-right: 10px;
      }
    }
  }

  &__content {
    display: none;
    transition: all .3s;
    margin-top: 2px;
  }

  &__item {
    cursor: pointer;
    font-size: 18px;
    box-shadow: 0 1px 3px rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05);
    background: #fff;
    padding: 15px 24px 15px 24px;
    transition: all .2s;

    @media screen and (max-width: 768px) {
      font-size: 14px;
      padding: 10px 20px;
    }

    @media screen and (max-width: 768px) {
      font-size: 12px;
    }

    &:hover {
      color: $primary-color;
    }
  }

  &.active {
    .tab-choice__content {
      display: block;
    }

    & > .tab-choice__link > .icon {
      transform: rotate(180deg);
    }
  }
}

.download {
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  outline: none;
  border: none;
  background: #00d983;
  color: #fff;
  letter-spacing: .5px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.2em;
  padding: 2px 10px;
  cursor: pointer;
  min-height: 39px;
  height: 0;
  white-space: nowrap;
  text-align: center;

  &--green {
    background: $accent-color;

    @media screen and (max-width: 550px) {
      width: 100%;
    }
  }

  &--blue {
    background-image: linear-gradient(225deg, #009dff, #0064ff);
    line-height: 1.5em;
    word-break: break-all;
    font-size: 12px;
    letter-spacing: .5px;
  }

  &.disabled {
    background: grey;
    cursor: default;
  }
}

.btn {
  width: 227px;

  line-height: normal;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  word-wrap: break-word;
  font-weight: 600;
  outline: none;

  background-color: $accent-color;
  color: #ffffff;

  border: none;
  border-radius: 4px;

  padding-top: 12px;
  padding-bottom: 10px;

  &--white {
    color: $color-text;
    background-color: #ffffff;
    border: none;
    box-shadow: 0px 1px 3px rgba(63, 63, 68, 0.15), 0px 0px 0px rgba(63, 63, 68, 0.05);
    border-radius: 4px;
  }
}

.screen {
  @include flexbox();
  @include flex-wrap(wrap);
  @include flex-grow(1);
  position: relative;
  background-image: linear-gradient(225deg, #009dff 0%, #0064ff 100%);
  overflow-x: hidden;

  @media screen and (max-width: 900px) {
    overflow-x: initial;
  }

  &__item {
    width: 50%;
    min-height: 400px;


    @media screen and (max-width: 900px) {
      width: 100%;
      min-height: auto;
    }


    &:first-child {
      background: #fff;
      z-index: 2;
      position: relative;
    }

    &:last-child {
      @media screen and (max-width: 900px) {
        height: 300px;
        position: relative;
      }

    }
  }

  &__content {
    text-align: center;
    width: calc(100% - 125px);
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 75px;

    @media screen and (max-width: 1560px) {
      width: calc(100% - 40px);
    }

    @media screen and (max-width: 1350px) {
      margin-top: 70px;
    }

    @media screen and (max-width: 900px) {
      margin-top: 0;
    }

    @media screen and (max-width: 550px) {
      width: calc(100% - 20px);
    }
  }

  &__picture {
    height: 100%;
    position: relative;
  }

  &__icon-oil {
    width: auto;
    position: absolute;
    z-index: 1;
    height: auto;
    bottom: 0;
    max-height: calc(100% - 60px);
    max-width: calc(100% - 40px);
    left: 50%;
    transform: translateX(-40%);

    @media screen and (max-width: 900px) {
      top: 50%;
      max-height: calc(100% - 20px);
      transform: translate(-40%, -44%);
    }

    @media screen and (max-width: 500px) {
      width: 250px;
      max-width: none;
      max-height: calc(100% - 10px);
    }
  }

  &__icon-map {
    position: absolute;
    top: -30%;
    right: -211px;
    width: 77%;
    height: auto;
    z-index: 0;

    @media screen and (max-width: 900px) {
      right: 0;
      top: -30px;
      width: 100%;
    }
  }

  &__content-header {
    & + * {
      margin-top: 50px;

      @media screen and (max-width: 1300px) {
        margin-top: 40px;
      }

      @media screen and (max-width: 480px) {
        margin-top: 30px;
      }
    }
  }

  &__btn-wrap {
    margin-top: 60px;

    @media screen and (max-width: 1300px) {
      margin-top: 20px;
    }

    @media screen and (max-width: 900px) {
      margin-top: 40px;
    }
  }

  p {
    font-size: 18px;
    font-weight: 400;
    font-family: "Open Sans";
    line-height: 1.34em;

    @media screen and (max-width: 1300px) {
      font-size: 16px;
    }

    @media screen and (max-width: 900px) {
      font-size: 14px;
    }
  }
}


.cursor-default {
  cursor: default
}

.publication-block {
  & + & {
    margin-top: 50px;
  }
}

.publication-list {
  @include flexbox();
  @include justify-content(space-between);
  @include flex-wrap(wrap);
  padding-top: 10px;

  &__item {
    width: calc(50% - 25px);
    margin-top: 17px;

    @media screen and (max-width: 1024px) {
      width: calc(50% - 10px);
    }

    @media screen and (max-width: 800px) {
      width: 100%;
    }

    &:nth-child(-n+2) {
      margin-top: 0;
    }

    &:nth-child(2) {
      @media screen and (max-width: 800px) {
        margin-top: 17px;
      }
    }
  }
}

.publication {
  @include flexbox();
  box-shadow: 0 1px 3px rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05);
  background-color: #ffffff;
  padding: 10px 30px 10px 10px;
  border-radius: 4px;
  color: #000000;
  font-size: 13px;
  line-height: 22px;

  &__content {
    @include flexbox();
    @include flex-direction(column);
    width: 100%;
  }

  &--main {
    border: 2px solid #008fff;
  }

  @media screen and (max-width: 550px) {
    @include flex-wrap (wrap);
    padding-right: 10px;
  }

  &__descr {
    display: block;
    color: black;
    margin-top:15px;
  }

  &__ttl {
    color: #002257;
    display: block;
  }

  &__subttl {
    font-size: 13px;
    font-weight: 400;
    line-height: 22px;
  }

  &__picture {
    @include flex-shrink(0);
    width: 100px;
    height: 150px;
    overflow: hidden;

    @media screen and (max-width: 550px) {
      width: 100%;
      text-align: center;
    }

    & + * {
      margin-left: 25px;

      @media screen and (max-width: 550px) {
        margin-left: 0;
        margin-top: 10px;
      }
    }
  }

  img {
    @media screen and (max-width: 550px) {
      margin: 0 auto;
    }
  }
}

.pagination {
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  margin-top: 40px;

  .disabled {
    opacity: .5;
    pointer-events: none;

    .icon {
      fill: #4a4a4a;
    }
  }

  &__prev,
  &__next {
    width: 36px;
    height: 36px;
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    border: 1px solid #bbbbbb;
    background-color: #f9f9f9;
    cursor: pointer;

    .icon {
      width: 9px;
      height: 11px;
      fill: #008fff;
    }
  }

  &__prev {
    border-radius: 4px 0 0 4px;
    border-right: 0;

    .icon {
      transform: rotate(180deg);
      transform-origin: center;
    }
  }

  &__next {
    border-radius: 0 4px 4px 0;
  }

  &__page {
    @include flexbox();
    @include align-items(center);
  }

  &__input {
    height: 36px;
    width: 56px;
    color: #4a4a4a;
    border: 1px solid #bbbbbb;
    background-color: #ffffff;
    text-align: center;
  }

  &__total {
    color: $color-link;
    margin-right: 8px;
  }

  &__text {
    margin: 0 3px 0 9px;
  }
}

.not-found-wrap {
  @include flex-grow(1);
  @include flexbox();
  @include align-items(center);
  max-width: 740px;
  width: 100%;
  margin: 0 auto;
  padding: 40px 10px;
}

.not-found {
  text-align: center;
  width: 100%;

  &__ttl {
    font-size: 180px;
    margin-bottom: 20px;
    line-height: 1.1;
    color: #0464FF;

    @media screen and (max-width: 800px) {
      font-size: 120px;
    }

    @media screen and (max-width: 767px) {
      font-size: 80px;
      margin-bottom: 10px;
    }

    @media screen and (max-width: 550px) {
      font-size: 30px;
    }
  }

  &__subttl {
    font-size: 39px;
    margin-bottom: 10px;
    line-height: 1.4;

    @media screen and (max-width: 550px) {
      font-size: 20px;
    }
  }

  &__desc {

    p {
      font-size: 14px;
      line-height: 1.4;

      @media screen and (max-width: 550px) {
        font-size: 10px;
      }
    }

    p + p {
      margin-top: 10px;
    }
  }
}

.icon-link {
  width: 10px;
  height: 10px;
  fill: #0067ff;
  margin-left: 5px;
}

.tooltip {
  position: relative;
  margin-left: 10px;

  &__head {
    background-color: #0464FF;
    @include flexbox();
    @include align-items(center);
    color: #fafafa;
    letter-spacing: 1px;
    border-radius: 3px;
    cursor: pointer;
    padding: 3px;
    padding-right: 5px;
    position: relative;
  }

  .icon {
    width: 20px;
    height: 15px;
    fill: #fff;
    stroke: #fff;

    & + * {
      margin-left: 5px;
    }
  }

  &--panel {
    @media screen and (max-width: 1000px) {
      margin-left: 0;
    }
  }

  &--table {
    width: 100%;
    margin-left: 0;

    &:hover {
      .tooltip__head {
        opacity: 1;
        visibility: visible;
        width: 20px;
      }
    }

    .tooltip__head {
      opacity: 0;
      visibility: hidden;
      margin-left: auto;
      background: transparent;
      padding-right: 3px;
    }

    .icon {
      height: 20px;
      fill: #6c757d;
    }
  }
}

.explore-by-company {
  &__pending {
    .tab-choice__link {
      top: 96px;
    }

    .rt-thead {
      top: 171px !important;
    }

    .filter-group {
      position: sticky;
      top: 0px;
      z-index: 3;
    }

    @media screen and (max-width: 1000px) {
      .tab-choice__link {
        top: 286px;
      }

      .rt-thead {
        top: 391px !important;
      }
    }

    @media screen and (max-width: 550px) {
      .tab-choice__link {
        top: 199px;
      }

      .rt-thead {
        top: 286px !important;
      }
    }
  }

  .tab-choice__link {
    top: 176px;
  }

  .rt-thead {
    top: 250px !important;
  }

  .filter-group {
    position: sticky;
    top: 81px;
    z-index: 3;
  }

  @media screen and (max-width: 1000px) {
    .tab-choice__link {
      top: 370px;
    }

    .rt-thead {
      top: 482px !important;
    }
  }

  @media screen and (max-width: 770px) {
    .tab-choice__link {
      top: 283px;
    }

    .rt-thead {
      top: 376px !important;
    }
  }

  @media screen and (max-width: 550px) {
    .tab-choice__link {
      top: 259px;
    }

    .rt-thead {
      top: 352px !important;
    }

    .filter-group {
      top: 60px;
    }
  }
}

.explore-by-indicator__pending {
  .panel__header {
    top: 0;
  }

  .rt-thead {
    top: 58px !important;
  }

  @media screen and (max-width: 1000px) {
    .rt-thead {
      top: 94px !important;
    }
  }

  @media screen and (max-width: 770px) {
    .rt-thead {
      top: 91px !important;
    }
  }
}

#notice-tooltip, #download-tooltip {
  width: 300px;
  text-align: center;
  padding: 5px;
}
