.modal {
  position: fixed;
  display: flex;
  flex-direction: column;

  z-index: 2;

  width: 580px;
  min-height: 300px;
  max-height: calc(100vh - 120px);

  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  padding-top: 45px;
  padding-bottom: 39px;

  color: $modal-text;
  background-color: #ffffff;
  box-shadow: 7px 7px 96px rgba(0, 0, 0, 0.5);
  border-radius: 4px;

  padding-left: 33px;
  padding-right: 33px;

  @media screen and (max-width:660px) {
    width: 420px;
    padding-left: 20px;
    padding-right: 20px;
  }

  @media screen and (max-width:460px) {
    width: 280px;
  }


  &__footer {
    @include flexbox();
    @include flex-shrink(0);
    @include justify-content(space-between);

    @media screen and (max-width:660px) {
      @include align-items(center);
      @include flex-direction(column-reverse);
    }
  }

  &__title {
    font-weight: normal;
    line-height: 28px;
    font-size: 24px;
    text-align: center;
    word-wrap: break-word;
    @include flex-shrink(0);

    width: 100%;
    margin-bottom: 28px;
  }

  &__content {
    flex-grow: 1;
    overflow: auto;
    margin-bottom: 40px;
    font-weight: 300;
    line-height: 27px;
    font-size: 17px;
    text-align: center;
    word-wrap: break-word;

    @media screen and (max-width:460px) {
      font-size: 14px;
      margin-bottom: 20px;
    }
  }

  &__text-strong {
    font-weight: normal;
    color: $modal-text;
  }

  &__close {
    position: absolute;
    right: 14px;
    top: 18px;
    width: 24px;
    height: 20px;
  }

  .btn + .btn {
    @media screen and (max-width:660px) {
      margin-bottom: 20px;
    }
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.55);
  overflow: hidden;
}
