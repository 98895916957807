$gradient-color:linear-gradient(225deg, #009dff 0%, #0064ff 100%);
$accent-color:#00D983;
$color-link: #0464FF;
$color-text: #002257;
$modal-text: #505050;
$primary-color:#0464FF;
$primary-color-hover:rgba(0, 103, 255,.5);
$bg-section:#F3F6FA;
$color-text-dropdown:#37394c;

/* Media breakpoints */
$screen-xs: 550px;
$screen-sm: 769px;
$screen-sm-plus: 800px;
$screen-md: 1024px;
$screen-md-plus: 1200px;
$screen-lg: 1380px;
