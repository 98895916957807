
@font-face {
    font-family: 'Futura bold';
    src: url('../fonts/FuturaStd-Bold.eot');
    src: url('../fonts/FuturaStd-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FuturaStd-Bold.woff2') format('woff2'),
        url('../fonts/FuturaStd-Bold.woff') format('woff'),
        url('../fonts/FuturaStd-Bold.ttf') format('truetype'),
        url('../fonts/FuturaStd-Bold.svg#FuturaStd-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}


@font-face {
    font-family: 'Futura med';
    src: url('../fonts/FuturaStd-Medium.eot');
    src: url('../fonts/FuturaStd-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FuturaStd-Medium.woff2') format('woff2'),
        url('../fonts/FuturaStd-Medium.woff') format('woff'),
        url('../fonts/FuturaStd-Medium.ttf') format('truetype'),
        url('../fonts/FuturaStd-Medium.svg#FuturaStd-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

