@import 'variables';
@import 'mixins';

img {
  max-width: 100%;
}

svg {
  transition: all 0.3s;
}

.container {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 20px;

  @media screen and (max-width: 769px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media screen and (max-width: $screen-xs) {
    padding-left: 10px;
    padding-right: 10px;
  }


  &--md {
    max-width: 1080px;
    padding: 0 20px;
  }
}

.main-screen {
  @include flex-grow(1);
  @include flexbox();
  @include flex-direction(column);
  @include justify-content(space-between);
}

.cursor-pointer {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

strong {
  font-weight: bold;
}

p {
  margin: 0 0 5px;

  &:last-child{
    margin-bottom: 0
  }
}

.p {
  &t {
    &-0 {
      padding-top: 0;
    }
  }

  &b {
    &-0 {
      padding-bottom: 0;
    }
  }
}


.section{
  background: $bg-section;
  padding: 10px 20px 30px 20px;

  @media screen and (max-width:769px){
      padding-left: 20px;
      padding-right: 20px;
  }

  @media screen and (max-width:550px){
      padding-left: 10px;
      padding-right: 10px;
  }

  &__wrap-center{
    @include flexbox() ;
    @include justify-content(center);
    padding-top: 30px;
  }

  &--map {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &--page {
    padding: 40px 0;
  }
}

.main-content {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}

.justify-content-between{
  @include justify-content(space-between);
}
