/* ==========================================================================
   Normalize.scss settings
   ========================================================================== */
/**
 * Includes legacy browser support IE6/7
 *
 * Set to false if you want to drop support for IE6 and IE7
 */
/* Base
    ========================================================================== */
/**
  * 1. Set default font family to sans-serif.
  * 2. Prevent iOS and IE text size adjust after device orientation change,
  *    without disabling user zoom.
  * 3. Corrects text resizing oddly in IE 6/7 when body `font-size` is set using
  *  `em` units.
  */
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/**
  * Remove default margin.
  */
body {
  margin: 0;
}

/* HTML5 display definitions
    ========================================================================== */
/**
  * Correct `block` display not defined for any HTML5 element in IE 8/9.
  * Correct `block` display not defined for `details` or `summary` in IE 10/11
  * and Firefox.
  * Correct `block` display not defined for `main` in IE 11.
  */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

/**
  * 1. Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
  * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
  */
audio,
canvas,
progress,
video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
  * Prevents modern browsers from displaying `audio` without controls.
  * Remove excess height in iOS 5 devices.
  */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
  * Address `[hidden]` styling not present in IE 8/9/10.
  * Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
  */
[hidden],
template {
  display: none;
}

/* Links
    ========================================================================== */
/**
  * Remove the gray background color from active links in IE 10.
  */
a {
  background-color: transparent;
}

/**
  * Improve readability of focused elements when they are also in an
  * active/hover state.
  */
a:active, a:hover {
  outline: 0;
}

/* Text-level semantics
    ========================================================================== */
/**
  * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
  */
abbr[title] {
  border-bottom: 1px dotted;
}

/**
  * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
  */
b,
strong {
  font-weight: bold;
}

/**
  * Address styling not present in Safari and Chrome.
  */
dfn {
  font-style: italic;
}

/**
  * Address variable `h1` font-size and margin within `section` and `article`
  * contexts in Firefox 4+, Safari, and Chrome.
  */
/**
  * Addresses styling not present in IE 8/9.
  */
mark {
  background: #ff0;
  color: #000;
}

/**
  * Address inconsistent and variable font size in all browsers.
  */
small {
  font-size: 80%;
}

/**
  * Prevent `sub` and `sup` affecting `line-height` in all browsers.
  */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* Embedded content
    ========================================================================== */
/**
  * 1. Remove border when inside `a` element in IE 8/9/10.
  * 2. Improves image quality when scaled in IE 7.
  */
img {
  border: 0;
}

/**
  * Correct overflow not hidden in IE 9/10/11.
  */
svg:not(:root) {
  overflow: hidden;
}

/* Grouping content
    ========================================================================== */
/**
  * Address margin not present in IE 8/9 and Safari.
  */
figure {
  margin: 1em 40px;
}

/**
  * Address differences between Firefox and other browsers.
  */
hr {
  box-sizing: content-box;
  height: 0;
}

/**
  * Contain overflow in all browsers.
  */
pre {
  overflow: auto;
}

/**
  * Address odd `em`-unit font size rendering in all browsers.
  * Correct font family set oddly in IE 6, Safari 4/5, and Chrome.
  */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

/* Forms
    ========================================================================== */
/**
  * Known limitation: by default, Chrome and Safari on OS X allow very limited
  * styling of `select`, unless a `border` property is set.
  */
/**
  * 1. Correct color not being inherited.
  *  Known issue: affects color of disabled elements.
  * 2. Correct font properties not being inherited.
  * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
  * 4. Improves appearance and consistency in all browsers.
  */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */
}

/**
  * Address `overflow` set to `hidden` in IE 8/9/10/11.
  */
button {
  overflow: visible;
}

/**
  * Address inconsistent `text-transform` inheritance for `button` and `select`.
  * All other form control elements do not inherit `text-transform` values.
  * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
  * Correct `select` style inheritance in Firefox.
  */
button,
select {
  text-transform: none;
}

/**
  * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
  *  and `video` controls.
  * 2. Correct inability to style clickable `input` types in iOS.
  * 3. Improve usability and consistency of cursor style between image-type
  *  `input` and others.
  * 4. Removes inner spacing in IE 7 without affecting normal text inputs.
  *  Known issue: inner spacing remains in IE 6.
  */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
}

/**
  * Re-set default cursor for disabled elements.
  */
button[disabled],
html input[disabled] {
  cursor: default;
}

/**
  * Remove inner padding and border in Firefox 4+.
  */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
  * Address Firefox 4+ setting `line-height` on `input` using `!important` in
  * the UA stylesheet.
  */
input {
  line-height: normal;
}

/**
  * 1. Address box sizing set to `content-box` in IE 8/9/10.
  * 2. Remove excess padding in IE 8/9/10.
  *  Known issue: excess padding remains in IE 6.
  */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
  * Fix the cursor style for Chrome's increment/decrement buttons. For certain
  * `font-size` values of the `input`, it causes the cursor style of the
  * decrement button to change from `default` to `text`.
  */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
  * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
  * 2. Address `box-sizing` set to `border-box` in Safari and Chrome.
  */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  box-sizing: content-box;
  /* 2 */
}

/**
  * Remove inner padding and search cancel button in Safari and Chrome on OS X.
  * Safari (but not Chrome) clips the cancel button when the search input has
  * padding (and `textfield` appearance).
  */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
  * Define consistent border, margin, and padding.
  */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
  * 1. Correct `color` not being inherited in IE 8/9/10/11.
  * 2. Remove padding so people aren't caught out if they zero out fieldsets.
  * 3. Corrects text not wrapping in Firefox 3.
  * 4. Corrects alignment displayed oddly in IE 6/7.
  */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
  * Remove default vertical scrollbar in IE 8/9/10/11.
  */
textarea {
  overflow: auto;
}

/**
  * Don't inherit the `font-weight` (applied by a rule above).
  * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
  */
optgroup {
  font-weight: bold;
}

/* Tables
    ========================================================================== */
/**
  * Remove most spacing between table cells.
  */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

/* Media breakpoints */
img {
  max-width: 100%;
}

svg {
  transition: all 0.3s;
}

.container {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 20px;
}

@media screen and (max-width: 769px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 550px) {
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.container--md {
  max-width: 1080px;
  padding: 0 20px;
}

.main-screen {
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
}

.cursor-pointer {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

strong {
  font-weight: bold;
}

p {
  margin: 0 0 5px;
}

p:last-child {
  margin-bottom: 0;
}

.pt-0 {
  padding-top: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.section {
  background: #F3F6FA;
  padding: 10px 20px 30px 20px;
}

@media screen and (max-width: 769px) {
  .section {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 550px) {
  .section {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.section__wrap-center {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  padding-top: 30px;
}

.section--map {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.section--page {
  padding: 40px 0;
}

.main-content {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}

.justify-content-between {
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
}

/* Media breakpoints */
html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

.fixed {
  overflow-y: hidden;
  position: fixed;
  left: 0;
  width: 100%;
  overflow-y: scroll;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

.svg-sprite {
  display: none;
}

body {
  max-width: 100%;
  min-height: 100vh;
  font-size: 14px;
  line-height: 21px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  color: #002257;
  background: #F3F6FA;
  overflow: auto;
  overflow-x: hidden;
}

body > div {
  height: 100%;
}

body > div > div {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}

h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child {
  margin-top: 0;
}

h1 + h1, h2 + h1, h3 + h1, h4 + h1, h5 + h1, h1 + h2, h2 + h2, h3 + h2, h4 + h2, h5 + h2, h1 + h3, h2 + h3, h3 + h3, h4 + h3, h5 + h3, h1 + h4, h2 + h4, h3 + h4, h4 + h4, h5 + h4, h1 + h5, h2 + h5, h3 + h5, h4 + h5, h5 + h5 {
  margin-top: 0;
}

h1.h1 {
  font-size: 36px;
  font-weight: 800;
  margin-bottom: 0.9em;
  line-height: 1.2em;
  font-family: 'Futura bold';
  letter-spacing: -.9px;
}

@media screen and (max-width: 1300px) {
  h1.h1 {
    font-size: 32px;
  }
}

@media screen and (max-width: 766px) {
  h1.h1 {
    font-size: 25px;
  }
}

h4 {
  line-height: 1.4em;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.017em;
  font-family: 'Futura med';
}

@media screen and (max-width: 1300px) {
  h4 {
    font-size: 18px;
  }
}

h5 {
  font-size: 15px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 12px;
}

@media screen and (max-width: 550px) {
  h5 {
    font-size: 13px;
    margin-bottom: 10px;
  }
}

input {
  outline: 0;
  box-shadow: none;
  border-radius: 0;
}

input[type="text"] {
  -webkit-appearance: none;
}

a {
  text-decoration: none;
  transition: all 0.3s;
  color: #0464FF;
}

a:hover {
  text-decoration: none !important;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.btn-landing {
  background: #0464FF;
  width: 188px;
  display: inline-block;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  line-height: 20px;
  padding: 15px 5px;
  border-radius: 4px;
  outline: none;
  border: 0;
  cursor: pointer;
}

@media screen and (max-width: 1300px) {
  .btn-landing--main {
    padding: 10px 5px;
  }
}

h6, .h6 {
  font-size: 15px;
  letter-spacing: 0.1px;
  margin-top: 0;
  margin-bottom: 5px;
}

/* Media breakpoints */
.hide {
  display: none;
}

.select {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.select__lable {
  font-size: 13px;
  font-weight: 600;
  line-height: 22px;
}

@media screen and (max-width: 550px) {
  .select__lable {
    line-height: 1.2em;
  }
}

.select__wrap {
  margin-top: 10px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}

.select__wrap--md {
  width: 140px;
}

@media screen and (max-width: 550px) {
  .select__wrap--md {
    width: 100%;
  }
}

.select__text {
  font-size: 14px;
  font-weight: 600;
  padding-left: 9px;
  padding-right: 9px;
}

.select + .select {
  margin-left: 100px;
}

@media screen and (max-width: 1380px) {
  .select + .select {
    margin-left: 20px;
  }
}

@media screen and (max-width: 550px) {
  .select + .select {
    margin-left: 0;
    padding-top: 10px;
  }
}

.input-choice {
  position: relative;
  box-shadow: 0 1px 3px rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05);
  border-radius: 4px;
  background-color: #ffffff;
  outline: none;
}

.input-choice__link {
  display: flex;
  position: relative;
  padding: 10px 25px 10px 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.4em;
  word-break: break-all;
  cursor: pointer;
  color: #0464FF;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}

.input-choice__link span {
  overflow: hidden;
  text-overflow: ellipsis;
}

.input-choice__link .icon {
  width: 8px;
  height: 5px;
  fill: #0464FF;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  z-index: auto;
  pointer-events: none;
}

.input-choice__link .icon.external-link {
  top: 48%;
  right: 6px;
  width: 13px;
  height: 13px;
}

.input-choice__link:before {
  position: absolute;
  top: 25%;
  right: 0;
  content: "";
  height: 50%;
  width: 25px;
  background: #fff;
}

.input-choice__link:before {
  border-radius: 4px;
}

.input-choice__content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  box-shadow: 0 1px 3px rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05);
  background: #fff;
  max-height: 200px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 6;
}

.input-choice__item {
  font-size: 12px;
  font-weight: 600;
  padding: 10px;
  line-height: 1.4em;
  cursor: pointer;
  transition: all .2s;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.input-choice__item:hover {
  color: #0464FF;
}

.input-choice__item.active {
  color: #0464FF;
}

.input-choice__item + .input-choice__item {
  border-top: 1px solid #f1f1f1;
}

.input-choice.active .input-choice__content {
  display: block;
}

.input-choice--green .input-choice__link {
  color: #fff;
  background: #00D983;
}

.input-choice--green .input-choice__link .icon {
  fill: #fff;
}

.input-choice--green .input-choice__link:before {
  background: #00D983;
}

.input-choice--sources .input-choice__item {
  padding-right: 25px;
  position: relative;
}

.input-choice--sources .input-choice__item .icon {
  width: 10px;
  height: 10px;
  fill: #0067ff;
  position: absolute;
  top: 48%;
  transform: translateY(-50%);
  right: 8px;
}

.input-choice--lg {
  width: 155px;
}

@media screen and (max-width: 550px) {
  .input-choice--lg {
    width: 100%;
  }
}

.input-choice--md {
  width: 140px;
}

@media screen and (max-width: 550px) {
  .input-choice--md {
    width: 100%;
  }
}

.input-choice--sm {
  width: 70px;
}

@media screen and (max-width: 550px) {
  .input-choice--sm {
    width: 100%;
  }
}

.input-choice--xs {
  width: 70px;
}

@media screen and (max-width: 550px) {
  .input-choice--xs {
    width: 100%;
  }
}

.panel {
  border-radius: 4px;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  margin-top: 20px;
}

.panel__header {
  position: sticky;
  z-index: 5;
  top: 81px;
  background: white;
  padding: 23.5px 30px;
  padding-right: 10px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  box-shadow: 0 1px 3px rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05);
}

@media screen and (max-width: 1024px) {
  .panel__header {
    top: 94px;
  }
}

@media screen and (max-width: 550px) {
  .panel__header {
    top: 70px;
  }
}

@media screen and (max-width: 1200px) {
  .panel__header {
    padding: 15px 10px;
  }
}

@media screen and (max-width: 1000px) {
  .panel__header {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.panel__ttl {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.71px;
  line-height: 1.4em;
  white-space: nowrap;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 1000px) {
  .panel__ttl {
    white-space: normal;
  }
}

@media screen and (max-width: 768px) {
  .panel__ttl {
    font-size: 14px;
    letter-spacing: 0.4px;
    height: 40px;
  }
}

.panel__options {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-shrink: 0;
  flex-shrink: 0;
}

@media screen and (max-width: 1000px) {
  .panel__options {
    width: 100%;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 1000px) {
  .panel__options .tooltip {
    margin-top: 15px;
    margin-right: 10px;
  }
}

.panel__link {
  color: #0464FF;
}

@media screen and (max-width: 1000px) {
  .panel__link {
    margin-right: 20px;
    margin-top: 15px;
  }
}

.panel__link:hover {
  text-decoration: none;
}

.panel__link + * {
  margin-left: 62px;
}

@media screen and (max-width: 1000px) {
  .panel__link + * {
    margin-left: 0;
    margin-top: 15px;
  }
}

* ~ .panel__link {
  margin-left: 30px;
}

@media screen and (max-width: 1000px) {
  * ~ .panel__link {
    margin-left: 0;
    margin-right: 0;
  }
}

.panel__button {
  box-shadow: 0 1px 3px rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05);
  border-radius: 4px;
  background-color: #ffffff;
  padding: 10px 22px 10px 10px;
  color: #0464FF;
  cursor: pointer;
}

@media screen and (max-width: 550px) {
  .panel__button {
    width: 100%;
    padding: 10px;
    font-size: 12px;
  }
}

.panel__content {
  padding: 10px;
  -webkit-overflow-scrolling: touch;
}

@media screen and (max-width: 1200px) {
  .panel__content {
    padding: 15px 0;
    margin-left: 10px;
    margin-right: 10px;
  }
}

table {
  width: 100%;
  border: 1px solid #e9ebf2;
  font-size: 13px;
}

@media screen and (max-width: 1000px) {
  table {
    font-size: 12px;
    min-width: 950px;
  }
}

table .border-right {
  border-right: 1px solid #d4d6dd;
}

table td {
  border: 1px solid #e9ebf2;
  padding: 5px 10px;
  height: 53px;
  word-break: break-word;
}

@media screen and (max-width: 1000px) {
  table td {
    padding: 8px 5px;
    height: auto;
  }
}

table th {
  font-size: 14px;
  font-weight: 700;
  height: 76px;
  padding: 5px 10px;
  border: 1px solid #e9ebf2;
  border-bottom: 1px solid #d4d6dd;
  text-align: left;
}

@media screen and (max-width: 1200px) {
  table th {
    width: auto;
  }
}

@media screen and (max-width: 1000px) {
  table th {
    padding: 8px 5px;
    font-size: 13px;
    height: auto;
  }
}

table .blue {
  color: #0464FF;
  text-align: right;
  min-width: 75px;
  max-width: 143px;
}

.sorting {
  cursor: pointer;
  white-space: nowrap;
}

.sorting:after {
  content: "";
  width: 10px;
  background: url("../theme/icon-arrow-fill.svg");
  height: 5px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 7px;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-shrink: 0;
  flex-shrink: 0;
}

.sorting.-sort-asc:after {
  transform: rotateX(180deg);
}

.right {
  text-align: right;
}

.dropdown {
  position: relative;
  outline: none;
}

.dropdown__link {
  position: relative;
  padding: 12px 25px 12px 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5em;
  word-break: break-all;
  font-size: 12px;
  letter-spacing: 0.5px;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  display: block;
}

.dropdown .icon {
  width: 8px;
  height: 5px;
  fill: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  transition: all .2s;
}

.dropdown__content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  box-shadow: 0 1px 3px rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05);
  border-radius: 4px;
  background-color: #ffffff;
}

.dropdown.active .dropdown__content {
  display: block;
}

.dropdown.active .icon-arrow-choice {
  transform: translateY(-50%) rotate(180deg);
}

.dropdown__item {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  padding: 10px 10px;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.4em;
  cursor: pointer;
  color: #37394c;
  letter-spacing: 0.03em;
}

.dropdown__item + .dropdown__item {
  border-top: 1px solid #f3f3f3;
}

.dropdown__logo {
  width: 36px;
  height: 19px;
  border-radius: 10px;
  font-size: 10px;
  text-transform: uppercase;
  margin-right: 10px;
  font-weight: 600;
  text-align: center;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
}

.dropdown__logo--csv {
  background-color: rgba(0, 100, 255, 0.1);
  color: #0464FF;
}

.dropdown__logo--xml {
  background-color: rgba(255, 0, 162, 0.1);
  color: #ff00a2;
}

.dropdown__logo--prs {
  background-color: rgba(255, 155, 0, 0.1);
  color: #ff9b00;
}

.dropdown--blue .dropdown__link {
  background-image: linear-gradient(225deg, #009dff 0%, #0064ff 100%);
}

.dropdown--green .dropdown__link {
  background: #00D983;
}

.dropdown--up .icon {
  transform: translateY(-50%) rotate(180deg);
}

.dropdown--up .dropdown__content {
  top: auto;
  bottom: 100%;
}

.dropdown--up.active .icon {
  transform: translateY(-50%) rotate(0);
}

.dropdown--lg {
  width: 184px;
}

.dropdown--sm {
  width: 109px;
}

@media screen and (max-width: 550px) {
  .dropdown--sm {
    width: 100%;
  }
}

.dropdown--sm .dropdown__link {
  padding: 10px 25px 10px 10px;
  font-size: 14px;
  line-height: 1.4em;
}

.dropdown--sm .dropdown__item {
  padding-right: 5px;
}

.dropdown--sm .dropdown__logo {
  margin-right: 6px;
}

.filter-group {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  background: #fff;
  z-index: 8;
}

@media screen and (max-width: 1000px) {
  .filter-group {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.filter-group__item {
  width: calc(100% / 3);
  border: 1px solid #e1e8ee;
}

@media screen and (max-width: 1000px) {
  .filter-group__item {
    width: 100%;
    border-left: none;
    border-right: none;
  }
}

.filter-group__item + .filter-group__item {
  border-left: none;
}

@media screen and (max-width: 1000px) {
  .filter-group__item + .filter-group__item {
    border-top: none;
  }
}

.filter-group-dropdown {
  position: relative;
  z-index: 1;
  outline: none;
  height: 100%;
}

@media screen and (max-width: 1000px) {
  .filter-group-dropdown {
    z-index: auto;
  }
}

.filter-group-dropdown__link {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding: 16px 50px;
  cursor: pointer;
}

@media screen and (max-width: 1200px) {
  .filter-group-dropdown__link {
    padding: 16px 40px;
  }
}

@media screen and (max-width: 769px) {
  .filter-group-dropdown__link {
    padding: 10px 20px;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
  }
}

@media screen and (max-width: 550px) {
  .filter-group-dropdown__link {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.filter-group-dropdown__subttl {
  font-size: 13px;
  font-weight: 600;
  line-height: 22px;
  text-transform: uppercase;
  color: #97a3b4;
}

@media screen and (max-width: 769px) {
  .filter-group-dropdown__subttl {
    font-size: 12px;
  }
}

.filter-group-dropdown__text {
  width: 100%;
  height: 100%;
}

.filter-group-dropdown.active .filter-group-dropdown__content {
  display: block;
}

.filter-group-dropdown__content {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  margin-top: 1px;
  background: #fff;
  box-shadow: 0 1px 3px rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05);
  display: none;
  z-index: 1;
  max-height: 168px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

@media screen and (max-width: 1000px) {
  .filter-group-dropdown__content {
    margin-top: 0;
  }
}

.filter-group-dropdown__item {
  padding: 5px 50px;
  cursor: pointer;
  height: 0;
  min-height: 42px;
  line-height: 1.2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media screen and (max-width: 1200px) {
  .filter-group-dropdown__item {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media screen and (max-width: 769px) {
  .filter-group-dropdown__item {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 550px) {
  .filter-group-dropdown__item {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.filter-group-dropdown__item + .filter-group-dropdown__item {
  border-top: 1px solid #f1f1f1;
}

.filter-group-dropdown__ttl {
  position: relative;
  color: #37394c;
  font-size: 20px;
  font-weight: 700;
  padding-right: 20px;
  margin-top: 10px;
  line-height: 1.05;
  word-break: break-word;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

@media screen and (max-width: 769px) {
  .filter-group-dropdown__ttl {
    font-size: 18px;
    margin-top: 5px;
  }
}

.filter-group-dropdown__ttl .icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 16px;
  height: 10px;
  fill: #37394c;
  pointer-events: none;
}

@media screen and (max-width: 769px) {
  .filter-group-dropdown__ttl .icon {
    width: 14px;
    height: 14px;
  }
}

.filter-group-dropdown__img {
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-shrink: 0;
  flex-shrink: 0;
  width: 62px;
  height: 62px;
  margin-right: 46px;
  border-radius: 50%;
  background-color: rgba(0, 100, 255, 0.06);
}

@media screen and (max-width: 769px) {
  .filter-group-dropdown__img {
    margin-right: 20px;
    width: 40px;
    height: 40px;
  }
}

.radio {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-shadow: 0 1px 3px rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05);
  border-radius: 4px;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  overflow: hidden;
}

.radio input {
  position: absolute;
  opacity: 0;
}

.radio__link {
  padding: 10px 25px 10px 10px;
  line-height: 1.4em;
  word-break: break-all;
  background: #fff;
  display: block;
}

input:checked ~ .radio__link {
  background-image: linear-gradient(225deg, #009dff 0%, #0064ff 100%);
}

input:checked ~ .radio__link {
  color: #fff;
}

.radio--first {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.radio--last {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.tab-block {
  margin-top: 20px;
}

.tab-list__item + .tab-list__item {
  margin-top: 28px;
}

@media screen and (max-width: 768px) {
  .tab-list__item + .tab-list__item {
    margin-top: 15px;
  }
}

.tab-choice__link {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  box-shadow: 0 1px 3px rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05);
  border-radius: 4px;
  background-color: #ffffff;
  padding: 24px 31px 24px 24px;
  min-height: 75px;
  cursor: pointer;
  z-index: 2;
  position: sticky;
  top: 84px;
}

@media screen and (max-width: 550px) {
  .tab-choice__link {
    top: 60px;
  }
}

.tab-choice__link > span {
  color: #37394c;
  font-size: 20px;
  letter-spacing: 0.71px;
  flex-basis: 50%;
}

@media screen and (max-width: 768px) {
  .tab-choice__link {
    padding: 15px 20px;
    min-height: auto;
    font-size: 18px;
  }
}

@media screen and (max-width: 768px) {
  .tab-choice__link {
    font-size: 15px;
  }
}

@media screen and (max-width: 1000px) {
  .tab-choice__link > span {
    width: calc(100% - 31px) !important;
    flex-basis: auto !important;
  }
}

@media screen and (max-width: 1000px) {
  .tab-choice__link .tab-choice__container {
    margin: 0 !important;
  }
}

.tab-choice__link > .icon {
  width: 16px;
  height: 10px;
  fill: #37394c;
  transform: rotate(0deg);
  transition: all .3s;
  position: absolute;
  top: 32px;
  right: 25px;
}

@media screen and (max-width: 768px) {
  .tab-choice__link > .icon {
    width: 14px;
    height: 14px;
    top: 18px;
  }
}

.tab-choice__container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: 24px;
}

@media screen and (max-width: 1000px) {
  .tab-choice__container {
    margin: 24px 0;
    width: 100%;
  }
}

@media screen and (max-width: 1000px) {
  .tab-choice__container .tooltip {
    margin-top: 15px;
    margin-right: 10px;
  }
}

.tab-choice__content {
  display: none;
  transition: all .3s;
  margin-top: 2px;
}

.tab-choice__item {
  cursor: pointer;
  font-size: 18px;
  box-shadow: 0 1px 3px rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05);
  background: #fff;
  padding: 15px 24px 15px 24px;
  transition: all .2s;
}

@media screen and (max-width: 768px) {
  .tab-choice__item {
    font-size: 14px;
    padding: 10px 20px;
  }
}

@media screen and (max-width: 768px) {
  .tab-choice__item {
    font-size: 12px;
  }
}

.tab-choice__item:hover {
  color: #0464FF;
}

.tab-choice.active .tab-choice__content {
  display: block;
}

.tab-choice.active > .tab-choice__link > .icon {
  transform: rotate(180deg);
}

.download {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  outline: none;
  border: none;
  background: #00d983;
  color: #fff;
  letter-spacing: .5px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.2em;
  padding: 2px 10px;
  cursor: pointer;
  min-height: 39px;
  height: 0;
  white-space: nowrap;
  text-align: center;
}

.download--green {
  background: #00D983;
}

@media screen and (max-width: 550px) {
  .download--green {
    width: 100%;
  }
}

.download--blue {
  background-image: linear-gradient(225deg, #009dff, #0064ff);
  line-height: 1.5em;
  word-break: break-all;
  font-size: 12px;
  letter-spacing: .5px;
}

.download.disabled {
  background: grey;
  cursor: default;
}

.btn {
  width: 227px;
  line-height: normal;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  word-wrap: break-word;
  font-weight: 600;
  outline: none;
  background-color: #00D983;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding-top: 12px;
  padding-bottom: 10px;
}

.btn--white {
  color: #002257;
  background-color: #ffffff;
  border: none;
  box-shadow: 0px 1px 3px rgba(63, 63, 68, 0.15), 0px 0px 0px rgba(63, 63, 68, 0.05);
  border-radius: 4px;
}

.screen {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  position: relative;
  background-image: linear-gradient(225deg, #009dff 0%, #0064ff 100%);
  overflow-x: hidden;
}

@media screen and (max-width: 900px) {
  .screen {
    overflow-x: initial;
  }
}

.screen__item {
  width: 50%;
  min-height: 400px;
}

@media screen and (max-width: 900px) {
  .screen__item {
    width: 100%;
    min-height: auto;
  }
}

.screen__item:first-child {
  background: #fff;
  z-index: 2;
  position: relative;
}

@media screen and (max-width: 900px) {
  .screen__item:last-child {
    height: 300px;
    position: relative;
  }
}

.screen__content {
  text-align: center;
  width: calc(100% - 125px);
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 75px;
}

@media screen and (max-width: 1560px) {
  .screen__content {
    width: calc(100% - 40px);
  }
}

@media screen and (max-width: 1350px) {
  .screen__content {
    margin-top: 70px;
  }
}

@media screen and (max-width: 900px) {
  .screen__content {
    margin-top: 0;
  }
}

@media screen and (max-width: 550px) {
  .screen__content {
    width: calc(100% - 20px);
  }
}

.screen__picture {
  height: 100%;
  position: relative;
}

.screen__icon-oil {
  width: auto;
  position: absolute;
  z-index: 1;
  height: auto;
  bottom: 0;
  max-height: calc(100% - 60px);
  max-width: calc(100% - 40px);
  left: 50%;
  transform: translateX(-40%);
}

@media screen and (max-width: 900px) {
  .screen__icon-oil {
    top: 50%;
    max-height: calc(100% - 20px);
    transform: translate(-40%, -44%);
  }
}

@media screen and (max-width: 500px) {
  .screen__icon-oil {
    width: 250px;
    max-width: none;
    max-height: calc(100% - 10px);
  }
}

.screen__icon-map {
  position: absolute;
  top: -30%;
  right: -211px;
  width: 77%;
  height: auto;
  z-index: 0;
}

@media screen and (max-width: 900px) {
  .screen__icon-map {
    right: 0;
    top: -30px;
    width: 100%;
  }
}

.screen__content-header + * {
  margin-top: 50px;
}

@media screen and (max-width: 1300px) {
  .screen__content-header + * {
    margin-top: 40px;
  }
}

@media screen and (max-width: 480px) {
  .screen__content-header + * {
    margin-top: 30px;
  }
}

.screen__btn-wrap {
  margin-top: 60px;
}

@media screen and (max-width: 1300px) {
  .screen__btn-wrap {
    margin-top: 20px;
  }
}

@media screen and (max-width: 900px) {
  .screen__btn-wrap {
    margin-top: 40px;
  }
}

.screen p {
  font-size: 18px;
  font-weight: 400;
  font-family: "Open Sans";
  line-height: 1.34em;
}

@media screen and (max-width: 1300px) {
  .screen p {
    font-size: 16px;
  }
}

@media screen and (max-width: 900px) {
  .screen p {
    font-size: 14px;
  }
}

.cursor-default {
  cursor: default;
}

.publication-block + .publication-block {
  margin-top: 50px;
}

.publication-list {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-top: 10px;
}

.publication-list__item {
  width: calc(50% - 25px);
  margin-top: 17px;
}

@media screen and (max-width: 1024px) {
  .publication-list__item {
    width: calc(50% - 10px);
  }
}

@media screen and (max-width: 800px) {
  .publication-list__item {
    width: 100%;
  }
}

.publication-list__item:nth-child(-n+2) {
  margin-top: 0;
}

@media screen and (max-width: 800px) {
  .publication-list__item:nth-child(2) {
    margin-top: 17px;
  }
}

.publication {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  box-shadow: 0 1px 3px rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05);
  background-color: #ffffff;
  padding: 10px 30px 10px 10px;
  border-radius: 4px;
  color: #000000;
  font-size: 13px;
  line-height: 22px;
}

.publication__content {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
}

.publication--main {
  border: 2px solid #008fff;
}

@media screen and (max-width: 550px) {
  .publication {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-right: 10px;
  }
}

.publication__descr {
  display: block;
  color: black;
  margin-top: 15px;
}

.publication__ttl {
  color: #002257;
  display: block;
}

.publication__subttl {
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
}

.publication__picture {
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-shrink: 0;
  flex-shrink: 0;
  width: 100px;
  height: 150px;
  overflow: hidden;
}

@media screen and (max-width: 550px) {
  .publication__picture {
    width: 100%;
    text-align: center;
  }
}

.publication__picture + * {
  margin-left: 25px;
}

@media screen and (max-width: 550px) {
  .publication__picture + * {
    margin-left: 0;
    margin-top: 10px;
  }
}

@media screen and (max-width: 550px) {
  .publication img {
    margin: 0 auto;
  }
}

.pagination {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  margin-top: 40px;
}

.pagination .disabled {
  opacity: .5;
  pointer-events: none;
}

.pagination .disabled .icon {
  fill: #4a4a4a;
}

.pagination__prev, .pagination__next {
  width: 36px;
  height: 36px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  border: 1px solid #bbbbbb;
  background-color: #f9f9f9;
  cursor: pointer;
}

.pagination__prev .icon, .pagination__next .icon {
  width: 9px;
  height: 11px;
  fill: #008fff;
}

.pagination__prev {
  border-radius: 4px 0 0 4px;
  border-right: 0;
}

.pagination__prev .icon {
  transform: rotate(180deg);
  transform-origin: center;
}

.pagination__next {
  border-radius: 0 4px 4px 0;
}

.pagination__page {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}

.pagination__input {
  height: 36px;
  width: 56px;
  color: #4a4a4a;
  border: 1px solid #bbbbbb;
  background-color: #ffffff;
  text-align: center;
}

.pagination__total {
  color: #0464FF;
  margin-right: 8px;
}

.pagination__text {
  margin: 0 3px 0 9px;
}

.not-found-wrap {
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  max-width: 740px;
  width: 100%;
  margin: 0 auto;
  padding: 40px 10px;
}

.not-found {
  text-align: center;
  width: 100%;
}

.not-found__ttl {
  font-size: 180px;
  margin-bottom: 20px;
  line-height: 1.1;
  color: #0464FF;
}

@media screen and (max-width: 800px) {
  .not-found__ttl {
    font-size: 120px;
  }
}

@media screen and (max-width: 767px) {
  .not-found__ttl {
    font-size: 80px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 550px) {
  .not-found__ttl {
    font-size: 30px;
  }
}

.not-found__subttl {
  font-size: 39px;
  margin-bottom: 10px;
  line-height: 1.4;
}

@media screen and (max-width: 550px) {
  .not-found__subttl {
    font-size: 20px;
  }
}

.not-found__desc p {
  font-size: 14px;
  line-height: 1.4;
}

@media screen and (max-width: 550px) {
  .not-found__desc p {
    font-size: 10px;
  }
}

.not-found__desc p + p {
  margin-top: 10px;
}

.icon-link {
  width: 10px;
  height: 10px;
  fill: #0067ff;
  margin-left: 5px;
}

.tooltip {
  position: relative;
  margin-left: 10px;
}

.tooltip__head {
  background-color: #0464FF;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  color: #fafafa;
  letter-spacing: 1px;
  border-radius: 3px;
  cursor: pointer;
  padding: 3px;
  padding-right: 5px;
  position: relative;
}

.tooltip .icon {
  width: 20px;
  height: 15px;
  fill: #fff;
  stroke: #fff;
}

.tooltip .icon + * {
  margin-left: 5px;
}

@media screen and (max-width: 1000px) {
  .tooltip--panel {
    margin-left: 0;
  }
}

.tooltip--table {
  width: 100%;
  margin-left: 0;
}

.tooltip--table:hover .tooltip__head {
  opacity: 1;
  visibility: visible;
  width: 20px;
}

.tooltip--table .tooltip__head {
  opacity: 0;
  visibility: hidden;
  margin-left: auto;
  background: transparent;
  padding-right: 3px;
}

.tooltip--table .icon {
  height: 20px;
  fill: #6c757d;
}

.explore-by-company__pending .tab-choice__link {
  top: 96px;
}

.explore-by-company__pending .rt-thead {
  top: 171px !important;
}

.explore-by-company__pending .filter-group {
  position: sticky;
  top: 0px;
  z-index: 3;
}

@media screen and (max-width: 1000px) {
  .explore-by-company__pending .tab-choice__link {
    top: 286px;
  }
  .explore-by-company__pending .rt-thead {
    top: 391px !important;
  }
}

@media screen and (max-width: 550px) {
  .explore-by-company__pending .tab-choice__link {
    top: 199px;
  }
  .explore-by-company__pending .rt-thead {
    top: 286px !important;
  }
}

.explore-by-company .tab-choice__link {
  top: 176px;
}

.explore-by-company .rt-thead {
  top: 250px !important;
}

.explore-by-company .filter-group {
  position: sticky;
  top: 81px;
  z-index: 3;
}

@media screen and (max-width: 1000px) {
  .explore-by-company .tab-choice__link {
    top: 370px;
  }
  .explore-by-company .rt-thead {
    top: 482px !important;
  }
}

@media screen and (max-width: 770px) {
  .explore-by-company .tab-choice__link {
    top: 283px;
  }
  .explore-by-company .rt-thead {
    top: 376px !important;
  }
}

@media screen and (max-width: 550px) {
  .explore-by-company .tab-choice__link {
    top: 259px;
  }
  .explore-by-company .rt-thead {
    top: 352px !important;
  }
  .explore-by-company .filter-group {
    top: 60px;
  }
}

.explore-by-indicator__pending .panel__header {
  top: 0;
}

.explore-by-indicator__pending .rt-thead {
  top: 58px !important;
}

@media screen and (max-width: 1000px) {
  .explore-by-indicator__pending .rt-thead {
    top: 94px !important;
  }
}

@media screen and (max-width: 770px) {
  .explore-by-indicator__pending .rt-thead {
    top: 91px !important;
  }
}

#notice-tooltip, #download-tooltip {
  width: 300px;
  text-align: center;
  padding: 5px;
}

/* Media breakpoints */
.header {
  position: sticky;
  top: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 40px;
  padding-right: 40px;
  background-image: linear-gradient(225deg, #009dff 0%, #0064ff 100%);
  z-index: 10;
}

@media screen and (max-width: 1024px) {
  .header {
    padding: 22px 40px;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
  }
}

@media screen and (max-width: 769px) {
  .header {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 550px) {
  .header {
    padding: 10px;
  }
}

.header__nav {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
}

@media screen and (max-width: 1024px) {
  .header__nav {
    -webkit-flex-grow: initial;
    -moz-flex-grow: initial;
    -ms-flex-grow: initial;
    flex-grow: initial;
  }
}

.header__logo {
  margin-right: 70px;
}

.logo {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  cursor: pointer;
}

.logo img {
  width: 50px;
  height: 50px;
}

@media screen and (max-width: 1024px) {
  .navigation {
    position: fixed;
    background: #fff;
    right: 0;
    top: 0;
    width: 250px;
    z-index: 50;
    height: 100%;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-transform: translateX(250px);
    transform: translateX(250px);
    transition: all .5s;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  }
}

@media screen and (max-width: 1024px) {
  .navigation.active {
    transform: translateX(0);
  }
}

.navigation__top-mobile {
  display: none;
  padding: 15px 20px;
}

@media screen and (max-width: 1024px) {
  .navigation__top-mobile {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end;
    -ms-flex-pack: flex-end;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-shrink: 0;
    flex-shrink: 0;
  }
}

.navigation-list {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 1024px) {
  .navigation-list {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.navigation-list__item + .navigation-list__item {
  margin-left: 47px;
}

@media screen and (max-width: 1024px) {
  .navigation-list__item + .navigation-list__item {
    margin-left: 0;
  }
}

.navigation-list__item.active .navigation-list__link:after {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .navigation-list__item.active .navigation-list__link:after {
    height: 100%;
    width: 4px;
  }
}

.navigation-list__link {
  color: #fff;
  font-size: 15px;
  letter-spacing: 0.05em;
  font-weight: 600;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  height: 100%;
  padding: 30px 0px 30px 0px;
  position: relative;
  cursor: pointer;
  letter-spacing: 0.01em;
}

@media screen and (max-width: 1024px) {
  .navigation-list__link {
    color: #0464FF;
    padding: 15px 20px;
  }
}

.navigation-list__link:after {
  content: '';
  display: block;
  width: 0;
  height: 4px;
  background: #00D983;
  position: absolute;
  left: 50%;
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 0;
  transition: all .2s;
}

@media screen and (max-width: 1024px) {
  .navigation-list__link:after {
    width: 4px;
    height: 0;
    left: 0;
    top: 50%;
    transform: none;
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    pointer-events: none;
  }
}

.navigation-list__link:hover:after {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .navigation-list__link:hover:after {
    height: 0;
    width: 4px;
    pointer-events: none;
  }
}

.navigation-list__link img {
  margin-right: 21px;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-shrink: 0;
  flex-shrink: 0;
}

@media screen and (max-width: 1024px) {
  .navigation-list__link img {
    display: none;
  }
}

.nav-toggle {
  position: relative;
  z-index: 2;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  -webkit-appearance: none;
  display: none;
}

@media screen and (max-width: 1024px) {
  .nav-toggle {
    display: block;
  }
}

.nav-toggle.active .line {
  background: #9f9f9f;
  width: 20px;
  height: 3px;
}

.nav-toggle.active .line:first-child {
  -moz-transform: translateY(11px) rotate(45deg);
  -o-transform: translateY(11px) rotate(45deg);
  -ms-transform: translateY(11px) rotate(45deg);
  -webkit-transform: translateY(11px) rotate(45deg);
  transform: translateY(11px) rotate(45deg);
}

.nav-toggle.active .line:nth-child(2) {
  opacity: 0;
}

.nav-toggle.active .line:last-child {
  -moz-transform: translateY(-11px) rotate(-45deg);
  -o-transform: translateY(-11px) rotate(-45deg);
  -ms-transform: translateY(-11px) rotate(-45deg);
  -webkit-transform: translateY(-11px) rotate(-45deg);
  transform: translateY(-11px) rotate(-45deg);
}

.line {
  display: block;
  width: 40px;
  height: 3px;
  border-radius: 1px;
  background-color: #fff;
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s,-webkit-transform .3s;
}

.line + .line {
  margin-top: 8px;
}

.footer {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(63, 63, 68, 0.15), 0 0 0 2px rgba(63, 63, 68, 0.05);
  padding: 20px 40px;
  font-size: 12px;
  font-weight: 400;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-shrink: 0;
  flex-shrink: 0;
}

@media screen and (max-width: 1000px) {
  .footer {
    display: block;
  }
}

@media screen and (max-width: 769px) {
  .footer {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 550px) {
  .footer {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 10px;
  }
}

.footer__item {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@media screen and (max-width: 1000px) {
  .footer__item {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.footer__item--lg {
  max-width: 62%;
}

@media screen and (max-width: 1000px) {
  .footer__item--lg {
    max-width: 100%;
  }
}

.footer__item + .footer__item {
  padding-left: 30px;
}

@media screen and (max-width: 1000px) {
  .footer__item + .footer__item {
    margin-top: 15px;
    padding-left: 0;
  }
}

.footer__item:first-child .footer__wrap-content a {
  display: inline-block;
  padding-right: 16px;
  position: relative;
}

.footer__item:first-child .footer__wrap-content a:after {
  content: "";
  position: absolute;
  height: 10px;
  width: 10px;
  top: 2px;
  right: 0;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 283.922 283.922' style='enable-background:new 0 0 283.922 283.922; fill:%230067ff' xml:space='preserve'%3E%3Cg%3E%3Cpath d='M266.422,0h-97.625c-9.65,0-17.5,7.851-17.5,17.5c0,9.649,7.85,17.5,17.5,17.5h55.377l-92.375,92.374 c-3.307,3.305-5.127,7.699-5.127,12.375c0,4.676,1.819,9.069,5.125,12.371c3.306,3.309,7.699,5.13,12.375,5.13 c4.674,0,9.069-1.82,12.376-5.127l92.374-92.375v55.377c0,9.649,7.851,17.5,17.5,17.5c9.649,0,17.5-7.851,17.5-17.5V17.5 C283.922,7.851,276.071,0,266.422,0z'/%3E%3Cpath d='M201.137,253.922H30V82.785h128.711l30-30H15c-8.284,0-15,6.716-15,15v201.137c0,8.284,6.716,15,15,15h201.137 c8.284,0,15-6.716,15-15V95.211l-30,30V253.922z'/%3E%3C/g%3E%3C/svg%3E");
}

.footer__logo {
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-shrink: 0;
  flex-shrink: 0;
  margin-right: 10px;
}

.footer__logo img {
  width: 101px;
  height: 40px;
}

@media screen and (max-width: 1000px) {
  .footer__logo {
    margin-bottom: 15px;
    margin-right: 0;
  }
}

.footer__wrap-content {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  width: 100%;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}

@media screen and (max-width: 1000px) {
  .footer__wrap-content {
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    -ms-flex-pack: flex-start;
  }
}

@media screen and (max-width: 550px) {
  .footer__wrap-content {
    font-size: 10px;
  }
}

.footer__email {
  font-weight: 700;
  line-height: 16px;
  margin-top: 10px;
}

.footer__email a {
  margin-left: 5px;
  font-weight: 600;
  white-space: nowrap;
}

.footer p {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}

@media screen and (max-width: 550px) {
  .footer p {
    font-size: 10px;
  }
}

.nav-black {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.nav-black__item + .nav-black__item {
  margin-left: 25px;
}

@media screen and (max-width: 1100px) {
  .nav-black__item + .nav-black__item {
    margin-left: 15px;
  }
}

.nav-black__link {
  font-size: 12px;
  font-weight: 600;
  line-height: 22px;
  color: #0464FF;
  letter-spacing: 0.01em;
  white-space: nowrap;
}

@media screen and (max-width: 550px) {
  .nav-black__link {
    font-size: 10px;
  }
}

.footer__logo-wrap {
  flex-shrink: 0;
}

@media screen and (max-width: 1000px) {
  .footer__logo-wrap {
    margin-bottom: 15px;
  }
}

.footer__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}

.footer__title-wrap {
  margin-right: 10px;
}

@media screen and (max-width: 1000px) {
  .footer__title-wrap {
    margin-right: 0;
    width: 100%;
  }
}

.footer__nav-wrap {
  padding-right: 35px;
}

@media screen and (max-width: 1100px) {
  .footer__nav-wrap .nav-black__item + .nav-black__item {
    margin-left: 25px;
  }
}

@media screen and (max-width: 1000px) {
  .footer__nav-wrap {
    padding-right: 0;
    width: 100%;
    order: 1;
    margin-top: 15px;
  }
}

.footer__text-wrap {
  margin-bottom: 5px;
}

.footer__link-wrap {
  margin-right: 10px;
}

.footer__link-wrap a {
  display: inline-block;
  padding-right: 16px;
  position: relative;
}

.footer__link-wrap a:after {
  content: "";
  position: absolute;
  height: 10px;
  width: 10px;
  top: 2px;
  right: 0;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 283.922 283.922' style='enable-background:new 0 0 283.922 283.922; fill:%230067ff' xml:space='preserve'%3E%3Cg%3E%3Cpath d='M266.422,0h-97.625c-9.65,0-17.5,7.851-17.5,17.5c0,9.649,7.85,17.5,17.5,17.5h55.377l-92.375,92.374 c-3.307,3.305-5.127,7.699-5.127,12.375c0,4.676,1.819,9.069,5.125,12.371c3.306,3.309,7.699,5.13,12.375,5.13 c4.674,0,9.069-1.82,12.376-5.127l92.374-92.375v55.377c0,9.649,7.851,17.5,17.5,17.5c9.649,0,17.5-7.851,17.5-17.5V17.5 C283.922,7.851,276.071,0,266.422,0z'/%3E%3Cpath d='M201.137,253.922H30V82.785h128.711l30-30H15c-8.284,0-15,6.716-15,15v201.137c0,8.284,6.716,15,15,15h201.137 c8.284,0,15-6.716,15-15V95.211l-30,30V253.922z'/%3E%3C/g%3E%3C/svg%3E");
}

@media screen and (max-width: 1000px) {
  .footer__link-wrap {
    width: 100%;
  }
}

.footer__email-wrap .footer__email {
  margin-top: 0;
}

@media screen and (max-width: 1000px) {
  .footer__email-wrap {
    margin-top: 10px;
    width: 100%;
    order: 1;
  }
}

.filter {
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  padding: 0 0 10px;
  background: transparent;
  position: relative;
}

.filter--center {
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media screen and (max-width: 1410px) {
  .filter {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
  }
}

@media screen and (max-width: 1000px) {
  .filter {
    padding-top: 10px;
  }
}

.filter__item {
  padding-top: 10px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  -ms-align-items: flex-end;
  align-items: flex-end;
}

@media screen and (max-width: 550px) {
  .filter__item {
    width: 100%;
    display: block;
  }
}

.filter__item + .filter__item {
  margin-left: 30px;
}

@media screen and (max-width: 550px) {
  .filter__item + .filter__item {
    width: 100%;
    margin-left: 0;
  }
}

.filter__options + .filter__item {
  margin-left: 30px;
}

@media screen and (max-width: 550px) {
  .filter__options + .filter__item {
    margin-left: 0;
  }
}

.filter__options {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  -ms-align-items: flex-end;
  align-items: flex-end;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  justify-content: flex-end;
  -ms-flex-pack: flex-end;
  margin-left: 30px;
}

@media screen and (max-width: 1380px) {
  .filter__options {
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-grow: 0;
    flex-grow: 0;
  }
}

@media screen and (max-width: 550px) {
  .filter__options {
    width: 100%;
    margin-left: 0;
  }
}

.filter__options-item {
  padding-top: 10px;
  min-height: 40px;
}

@media screen and (max-width: 550px) {
  .filter__options-item {
    width: 100%;
    display: block;
  }
}

.filter__options-item + .filter__options-item {
  margin-left: 30px;
}

@media screen and (max-width: 1380px) {
  .filter__options-item + .filter__options-item {
    margin-left: 20px;
  }
}

@media screen and (max-width: 550px) {
  .filter__options-item + .filter__options-item {
    margin-left: 0px;
  }
}

.filter__link {
  color: #0464FF;
}

@media screen and (max-width: 665px) {
  .filter__link {
    font-size: 13px;
  }
}

@media screen and (max-width: 550px) {
  .filter__link {
    font-size: 14px;
  }
}

.filter__button {
  box-shadow: 0 1px 3px rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05);
  border-radius: 4px;
  background-color: #fff;
  padding: 10px 22px 10px 10px;
  color: #0464FF;
  cursor: pointer;
  display: block;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 665px) {
  .filter__button {
    font-size: 13px;
  }
}

.ReactTable {
  border: 1px solid #e9ebf2;
  font-size: 13px;
  display: flex;
  flex-direction: column;
}

.ReactTable * {
  box-sizing: border-box;
}

.ReactTable .rt-table {
  flex: auto 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  border-collapse: collapse;
}

.ReactTable .rt-thead {
  position: sticky;
  top: 156px;
  background-color: #fff;
  min-width: unset !important;
  overflow: hidden;
  z-index: 1;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media screen and (max-width: 1200px) {
  .ReactTable .rt-thead {
    top: 139px;
  }
}

@media screen and (max-width: 1024px) {
  .ReactTable .rt-thead {
    top: 152px;
  }
}

@media screen and (max-width: 1000px) {
  .ReactTable .rt-thead {
    top: 194px;
  }
}

@media screen and (max-width: 768px) {
  .ReactTable .rt-thead {
    top: 206px;
  }
}

@media screen and (max-width: 550px) {
  .ReactTable .rt-thead {
    top: 182px;
  }
}

.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline: none;
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.6);
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.6);
}

.ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
  cursor: pointer;
}

.ReactTable .rt-thead .rt-th:last-child,
.ReactTable .rt-thead .rt-td:last-child {
  border-right: 0;
}

.ReactTable .rt-thead .rt-resizable-header {
  overflow: visible;
}

.ReactTable .rt-thead .rt-resizable-header:last-child {
  overflow: hidden;
}

.ReactTable .rt-thead .rt-resizable-header-content {
  overflow: hidden;
  text-overflow: ellipsis;
}

.ReactTable .rt-thead .rt-header-pivot {
  border-right-color: #f7f7f7;
}

.ReactTable .rt-thead .rt-header-pivot:after,
.ReactTable .rt-thead .rt-header-pivot:before {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ReactTable .rt-thead .rt-header-pivot:after {
  border-color: rgba(255, 255, 255, 0);
  border-left-color: #fff;
  border-width: 8px;
  margin-top: -8px;
}

.ReactTable .rt-thead .rt-header-pivot:before {
  border-color: rgba(102, 102, 102, 0);
  border-left-color: #f7f7f7;
  border-width: 10px;
  margin-top: -10px;
}

.ReactTable .rt-tbody {
  flex: 99999 1 auto;
  min-width: unset !important;
  overflow: hidden;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}

.ReactTable .rt-tbody .rt-expandable {
  cursor: pointer;
  text-overflow: clip;
}

.ReactTable .rt-tr-group {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.ReactTable .rt-tr {
  flex: 1 0 auto;
  display: inline-flex;
}

.ReactTable .rt-th.-hidden,
.ReactTable .rt-td.-hidden {
  width: 0 !important;
  min-width: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  opacity: 0 !important;
}

.ReactTable .rt-expander {
  display: inline-block;
  position: relative;
  margin: 0;
  color: transparent;
  margin: 0 10px;
}

.ReactTable .rt-expander:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  border-left: 5.04px solid transparent;
  border-right: 5.04px solid transparent;
  border-top: 7px solid rgba(0, 0, 0, 0.8);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer;
}

.ReactTable .rt-expander.-open:after {
  transform: translate(-50%, -50%) rotate(0deg);
}

.ReactTable .rt-resizer {
  display: inline-block;
  position: absolute;
  width: 36px;
  top: 0;
  bottom: 0;
  right: -18px;
  cursor: col-resize;
  z-index: 10;
}

.ReactTable .rt-tfoot {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0px 15px 0px rgba(0, 0, 0, 0.15);
}

.ReactTable .rt-tfoot .rt-td:last-child {
  border-right: 0;
}

.ReactTable.-striped .rt-tr.-odd {
  background: rgba(0, 0, 0, 0.03);
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: rgba(0, 0, 0, 0.05);
}

.ReactTable .-pagination {
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 3px;
  box-shadow: 0 0px 15px 0px rgba(0, 0, 0, 0.1);
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}

.ReactTable .-pagination input,
.ReactTable .-pagination select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline: none;
}

.ReactTable .-pagination .-btn {
  appearance: none;
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 3px;
  padding: 6px;
  font-size: 1em;
  color: rgba(0, 0, 0, 0.6);
  background: rgba(0, 0, 0, 0.1);
  transition: all 0.1s ease;
  cursor: pointer;
  outline: none;
}

.ReactTable .-pagination .-btn[disabled] {
  opacity: 0.5;
  cursor: default;
}

.ReactTable .-pagination .-btn:not([disabled]):hover {
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
}

.ReactTable .-pagination .-previous,
.ReactTable .-pagination .-next {
  flex: 1;
  text-align: center;
}

.ReactTable .-pagination .-center {
  flex: 1.5;
  text-align: center;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.ReactTable .-pagination .-pageInfo {
  display: inline-block;
  margin: 3px 10px;
  white-space: nowrap;
}

.ReactTable .-pagination .-pageJump {
  display: inline-block;
}

.ReactTable .-pagination .-pageJump input {
  width: 70px;
  text-align: center;
}

.ReactTable .-pagination .-pageSizeOptions {
  margin: 3px 10px;
}

.ReactTable .rt-noData {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: 1;
  pointer-events: none;
  padding: 20px;
  color: rgba(0, 0, 0, 0.5);
}

.ReactTable .-loading {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}

.ReactTable .-loading > div {
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  top: 50%;
  left: 0;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  transform: translateY(-52%);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.ReactTable .-loading.-active {
  opacity: 1;
  z-index: 2;
  pointer-events: all;
}

.ReactTable .-loading.-active > div {
  transform: translateY(50%);
}

.ReactTable .rt-resizing .rt-th,
.ReactTable .rt-resizing .rt-td {
  transition: none !important;
  cursor: col-resize;
  user-select: none;
}

.rt-th {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  height: 76px;
  padding: 5px 10px;
  border: 1px solid #e9ebf2;
  text-align: left;
  outline: none;
}

@media screen and (max-width: 1366px) {
  .rt-th {
    padding: 8px 5px;
    font-size: 13px;
    height: auto;
  }
}

.rt-td {
  display: flex;
  align-items: center;
  border: 1px solid #e9ebf2;
  padding: 5px 10px;
  min-height: 53px;
  word-break: break-word;
}

@media screen and (max-width: 1366px) {
  .rt-td {
    padding: 8px 5px;
    height: auto;
  }
}

.blue {
  color: #0464FF;
  justify-content: flex-end;
}

.document-list {
  margin-top: 40px;
}

.document {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  text-decoration: underline;
  color: #002257;
}

.document + .document {
  margin-top: 10px;
}

.document__icon {
  margin-right: 10px;
  display: block;
  flex-shrink: 0;
}

.document:hover {
  opacity: 0.5;
  text-decoration: underline;
}

.modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 2;
  width: 580px;
  min-height: 300px;
  max-height: calc(100vh - 120px);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding-top: 45px;
  padding-bottom: 39px;
  color: #505050;
  background-color: #ffffff;
  box-shadow: 7px 7px 96px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding-left: 33px;
  padding-right: 33px;
}

@media screen and (max-width: 660px) {
  .modal {
    width: 420px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 460px) {
  .modal {
    width: 280px;
  }
}

.modal__footer {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-shrink: 0;
  flex-shrink: 0;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
}

@media screen and (max-width: 660px) {
  .modal__footer {
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-flex-direction: column-reverse;
    -moz-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}

.modal__title {
  font-weight: normal;
  line-height: 28px;
  font-size: 24px;
  text-align: center;
  word-wrap: break-word;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-shrink: 0;
  flex-shrink: 0;
  width: 100%;
  margin-bottom: 28px;
}

.modal__content {
  flex-grow: 1;
  overflow: auto;
  margin-bottom: 40px;
  font-weight: 300;
  line-height: 27px;
  font-size: 17px;
  text-align: center;
  word-wrap: break-word;
}

@media screen and (max-width: 460px) {
  .modal__content {
    font-size: 14px;
    margin-bottom: 20px;
  }
}

.modal__text-strong {
  font-weight: normal;
  color: #505050;
}

.modal__close {
  position: absolute;
  right: 14px;
  top: 18px;
  width: 24px;
  height: 20px;
}

@media screen and (max-width: 660px) {
  .modal .btn + .btn {
    margin-bottom: 20px;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.55);
  overflow: hidden;
}

@font-face {
  font-family: 'Futura bold';
  src: url("../fonts/FuturaStd-Bold.eot");
  src: url("../fonts/FuturaStd-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaStd-Bold.woff2") format("woff2"), url("../fonts/FuturaStd-Bold.woff") format("woff"), url("../fonts/FuturaStd-Bold.ttf") format("truetype"), url("../fonts/FuturaStd-Bold.svg#FuturaStd-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Futura med';
  src: url("../fonts/FuturaStd-Medium.eot");
  src: url("../fonts/FuturaStd-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaStd-Medium.woff2") format("woff2"), url("../fonts/FuturaStd-Medium.woff") format("woff"), url("../fonts/FuturaStd-Medium.ttf") format("truetype"), url("../fonts/FuturaStd-Medium.svg#FuturaStd-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
}
